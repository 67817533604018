.authContainer {
  width: 100%;
  max-width: 1440px;
  // padding-left: 20px;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  // padding-right: 20px;
  position: relative;
  // height: 100%;
  margin: 0 auto;
  padding-top: 62px;
  .authCheckbox {
    width: 90%;
    margin-left: auto;
    // margin-right: 20px;
  }
  .footerArea {
    width: 100%;
    height: 80px;
  }
  & .authLogo {
    position: absolute;
    top: 39px;
    left: 55px;
  }
  .registration {
    margin-bottom: 28px;
    font-family: "SF UI Display Medium";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    @media screen and (max-width: 1024px) {
      font-size: 25px;
    }
    line-height: 30px;
    text-align: center;
    color: #22346d;
  }

  .wholeContainer {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    max-height: 550px;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 4px 1px #3287cd36;
    -moz-box-shadow: 0px 0px 4px 1px #3287cd36;
    box-shadow: 0px 0px 4px 1px #3287cd36;
    // padding-top: 40px;
    // padding-bottom: 39px;
    // -webkit-box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2) inset;
    // -moz-box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2) inset;
    // box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2) inset;
    .formContainer {
      width: 340px;
      margin: 0 auto;
    }
  }
}
.numberContainer {
  animation: codeAnimation 0.4s ease-in-out forwards;
}
.telContainer,
.numberContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 340px;
  .labelText {
    font-family: "SF UI Display Regular", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    @media screen and (max-width: 1024px) {
      font-size: 15px;
    }
    line-height: 30px;
    text-align: center;
    // margin-bottom: 10px;
    color: #636c83;
  }

  .phoneInput {
    width: 340px;
    margin-bottom: 38px;
    height: 70px;
    background: #f4f9fd;
    border-radius: 8px;
    border: none;
    font-size: 24px;
    @media screen and (max-width: 1024px) {
      font-size: 21px;
    }
    line-height: 30px;
    text-align: center;
    color: #262e42;
    margin-bottom: 20px;
    &:focus {
      border: 1px solid #3288cd;
    }
  }
  .codeInput {
    @extend .phoneInput;
    margin-bottom: 20px;
  }
}
// }

@keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes codeAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes codeAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.repeatContainer {
  animation: codeAnimation 0.4s ease-in-out forwards;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & img {
    transition: all 0.2s ease-in;
    &:hover,
    &:focus {
      cursor: pointer;
      transform: scale(1.04);
    }
  }
  & .repeatCodeImg {
    animation: 1s linear 0s normal none infinite running rot;
    -webkit-animation: 1s linear 0s normal none infinite running rot;
  }
  //   margin-bottom: 20px;
  .repeat {
    font-family: "SF UI Display Medium", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    @media screen and (max-width: 1024px) {
      font-size: 15px;
    }
    line-height: 30px;
    text-align: center;
    color: #000000;
    margin-bottom: 10px;
  }
}
.infoContainer {
  margin: 0 auto;
  // margin-top: 20px;
  width: 340px;
  height: 60px;
  font-family: "SF UI Display Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  @media screen and (max-width: 1024px) {
    font-size: 13px;
  }
  line-height: 20px;
  text-align: center;
  color: #000000;
  .hrefs {
    color: #3288cd;
    font-family: "SF UI Display Regular", sans-serif;
    font-size: 16px;
    @media screen and (max-width: 1024px) {
      font-size: 13px;
    }
    &:hover,
    &:focus {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
.numberContainer {
}
.buttonContainer {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
}

// #root > div.authContainer > div.wholeContainer > div.authCheckbox > label {
//   width: 80%;
//   margin: 0 auto;
// }
