.bookingPageTitleContainer {
  // padding-top: 20px;
  padding-bottom: 21px;
  width: 100%;
  max-width: 900px;
  display: flex;
  align-items: center;
  & > img {
    margin-right: 31px;
    &:hover,
    &:focus {
      cursor: pointer;
    }
  }
  .imageBox {
    width: 80px;
    height: 80px;
    background: #f4f9fd;
    border: 5px solid #ffffff;
    box-sizing: border-box;
    border-radius: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }

  .bookingPageTitle {
    font-family: "Roboto Bold", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    @media screen and (max-width: 1024px) {
      font-size: 25px;
    }
    line-height: 36px;
    color: #272d2d;
  }
}
.bookingContainer {
  width: 100%;
  max-width: 620px;
  // height: 609px;
  overflow: hidden;
  // overflow: auto;
  position: relative;
  // padding: 40px;
  border-radius: 8px;
  overflow-y: auto;
  margin-right: 20px;
}
.bookingStep1 {
  width: 100%;
  max-width: 620px;
  // height: 100%;
  padding: 40px;
  padding-top: 30px;
  padding-bottom: 20px;
  //delete
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  // z-index: 1;
  // height: 609px;
  // z-index: 3;
  height: 100%;
  max-height: 589px;
  // background-color: tomato;
  background: #ffffff;
  display: none;
  &.isActive {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: space-between;
    animation: stepForwardAnimation 0.3s ease-in-out;
  }
  .stepTitleContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .stepTitle {
      font-family: "SF UI Display Medium", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      @media screen and (max-width: 1024px) {
        font-size: 15px;
      }
      line-height: 20px;
      color: #07141f;
    }
    .stepCount {
      font-family: "SF UI Display Medium", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      @media screen and (max-width: 1024px) {
        font-size: 11px;
      }
      line-height: 20px;
      text-align: right;
      color: #3288cd;
    }
  }
}
.bookingStep2 {
  @extend .bookingStep1;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 95px;
  position: relative;
  max-height: 588px;
  display: none;
  &.isActive {
    display: flex;
    flex-direction: column;
    // left: 0;
    animation: stepForwardAnimation 0.3s ease-in-out;
  }
  & > .absoluteCenter {
    position: absolute;
    // top: 0;
    // left: 0;
    // bottom: 0;
    // right: 0;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    transform: translate(-50%, -50%);
  }
  .stepTitleContainer {
    width: calc(100% - 80px);
    position: absolute;
    top: 38px;
    // position: absolute;
    // overflow: hidden;
    // top: 30px;
    margin-bottom: 37px;
  }
  // .scrollContainer {
  //   overflow: auto;
  //   position: relative;
  //   scrollbar-width: none;
  //   &::-webkit-scrollbar {
  //     width: 4px;
  //     border-radius: 2px;
  //     background: unset;
  //   }
  //   &::-webkit-scrollbar-thumb {
  //     width: 4px;
  //     height: 10%;
  //     border-radius: 2px;
  //     background: #4b9bf5;
  //   }
  // }
  .chooseDoctor {
    // overflow: auto;
    font-family: "SF UI Display Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    @media screen and (max-width: 1024px) {
      font-size: 15px;
    }
    line-height: 20px;
    color: #07141f;
    margin-bottom: 10px;
  }
  .fieldBox {
    // overflow: auto;
  }
}
.bookingStep3 {
  @extend .bookingStep1;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 95px;
  position: relative;
  max-height: 588px;
  display: none;
  &.isActive {
    display: flex;
    flex-direction: column;
    // left: 0;
    animation: stepForwardAnimation 0.3s ease-in-out;
  }
  .stepTitleContainer {
    width: calc(100% - 80px);
    position: absolute;
    top: 38px;
    // position: absolute;
    // overflow: hidden;
    // top: 30px;
    margin-bottom: 37px;
  }
  // .scrollContainer {
  //   overflow: auto;
  //   position: relative;
  //   scrollbar-width: none;
  //   &::-webkit-scrollbar {
  //     width: 4px;
  //     border-radius: 2px;
  //     background: unset;
  //   }
  //   &::-webkit-scrollbar-thumb {
  //     width: 4px;
  //     height: 10%;
  //     border-radius: 2px;
  //     background: #4b9bf5;
  //   }
  // }
  .chooseDoctor {
    // overflow: auto;
    font-family: "SF UI Display Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    @media screen and (max-width: 1024px) {
      font-size: 15px;
    }
    line-height: 20px;
    color: #07141f;
    margin-bottom: 10px;
  }
  .fieldBox {
    // overflow: auto;
  }
}
.bookingStep4 {
  @extend .bookingStep3;
  .typeTitleContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .typeTitle {
      font-family: "SF UI Display Medium", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
      }
      line-height: 20px;
      color: #07141f;
    }
  }
}
.bookingStep5 {
  // overflow-y: auto;
  font-family: "SF UI Display Medium", sans-serif;
  @extend .bookingStep4;
  &.isActive .btn {
    height: 50px;
  }
  // .confirmationContainer {
  //   max-height: 430px;
  //   overflow-y: auto;
  //   &::-webkit-scrollbar {
  //     width: 4px;
  //     border-radius: 2px;
  //     background: unset;
  //   }
  //   &::-webkit-scrollbar-thumb {
  //     width: 4px;
  //     height: 10%;
  //     border-radius: 2px;
  //     background: #3288cd;
  //   }
  //   .confirmationItem {
  //     margin-bottom: 20px;
  //   }
  //   .confirmationItemTitle {
  //     font-family: "SF UI Display Regular", sans-serif;
  //     font-style: normal;
  //     font-weight: normal;
  //     font-size: 14px;
  //     line-height: 20px;
  //     color: #07141f;
  //     margin-bottom: 10px;
  //   }
  //   .confirmationCard {
  //     padding: 15px;
  //     display: flex;
  //     width: 100%;
  //     background: #f4f9fd;
  //     .userTime {
  //       @extend .confirmationItemTitle;
  //       margin-bottom: 0;
  //     }
  //   }
  //   .confirmationSpecialization {
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: center;
  //     align-items: center;
  //     padding: 5px;
  //     height: 120px;
  //     width: 166px;
  //     margin-right: 21px;
  //     background: #f4f9fd;
  //     border-radius: 8px;
  //     .specializationName {
  //       font-style: normal;
  //       font-weight: 500;
  //       font-size: 14px;
  //       line-height: 20px;
  //       text-align: center;
  //       color: #07141f;
  //     }
  //     & img {
  //       width: 40px;
  //       height: 40px;
  //       margin-bottom: 10px;
  //     }
  //   }
  // }
}

// @keyframes stepBackAnimation {
//   from {
//     left: -100%;
//   }
//   to {
//     left: 0px;
//   }
// }
@keyframes stepForwardAnimation {
  from {
    left: 100%;
    opacity: 0;
  }
  to {
    left: 0px;
    opacity: 1;
  }
}

///////////TYPE
.typeContainer {
  display: flex;
  justify-content: space-between;
  // justify-content: space-evenly;
  width: 100%;
  padding-right: 3px;
  padding-left: 3px;
}
// input:checked .optionLabel {
//   background: black;
// }
.typeLabel {
  background: #f4f9fd;
  border-radius: 8px;
  // width: 100%;
  // max-width: 166px;
  // width: 48%;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // transition: all 0.2s ease-in-out;
  width: 32%;
  @media screen and (max-width: 1024px) {
    max-width: 32%;
    width: 32%;
  }
  &:hover,
  &:focus {
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 4px 1px #3287cd69;
    -moz-box-shadow: 0px 0px 4px 1px #3287cd69;
    box-shadow: 0px 0px 4px 1px #3287cd69;
  }
  // transition: all 0.2 linear;
  &.typeSelected {
    background: #3288cd;
  }
  &.forTwo {
    width: 49%;
  }
  .typeTitle {
    font-family: "SF UI Display Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    @media screen and (max-width: 1024px) {
      font-size: 14px;
    }
    line-height: 20px;
    text-align: center;
    color: #07141f;
    &.activeTitle {
      color: #f8f8f8;
    }
  }
}

////////MEDCARD
.medContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-auto-rows: min-content;
  grid-auto-columns: max-content;
  grid-row-gap: 20px;
  color: #07141f;
  height: 100%;
  max-height: 184px;
  overflow-y: auto;
  padding: 2px;
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
    background: unset;
  }
  &::-webkit-scrollbar-thumb {
    width: 8px;
    // height: 10%;
    border-radius: 8px;
    background: #3287cdbe;
    opacity: 0.7;
  }
  &::-webkit-scrollbar-track {
    border-radius: 8px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.15);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #3288cd;
    cursor: pointer;
  }
  .createCard {
    padding: 15px;
    background: #f4f9fd;
    border-radius: 8px;
    width: 100%;
    max-width: 260px;
    display: flex;
    &.disabled {
      background: rgba(211, 211, 211, 0.24) !important;
      opacity: 0.6 !important;
      &:hover {
        box-shadow: none !important;
      }
    }
    &:hover,
    &:focus {
      cursor: pointer;
      -webkit-box-shadow: 0px 0px 4px 1px #3287cd69;
      -moz-box-shadow: 0px 0px 4px 1px #3287cd69;
      box-shadow: 0px 0px 4px 1px #3287cd69;
    }
    .addCardPicture {
      width: 50px;
      height: 50px;
      margin-right: 15px;
    }
    .createCardText {
      font-family: "SF UI Display Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }
      line-height: 30px;
      display: flex;
      align-items: center;
      color: #07141f;
    }
  }
  .medLabel {
    padding: 15px;
    background: #f4f9fd;
    border-radius: 8px;
    width: 100%;
    max-width: 260px;
    display: flex;
    height: 100%;
    max-height: 80px;
    // transition: all 0.2s ease-in-out;
    @media screen and (max-width: 1024px) {
      width: 100%;
      max-width: 100%;
    }
    & img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    &:hover,
    &:focus {
      cursor: pointer;
      -webkit-box-shadow: 0px 0px 4px 1px #3287cd69;
      -moz-box-shadow: 0px 0px 4px 1px #3287cd69;
      box-shadow: 0px 0px 4px 1px #3287cd69;
    }
    &.medSelected {
      background: #3288cd;
      color: #f8f8f8;
    }
    .userInfoContainer {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }
    .userTitle {
      font-family: "SF UI Display Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #07141f;
      // margin-bottom: 10px;
      &.activeTitle {
        color: #ffffff;
      }
    }
    .userRelation {
      font-family: "SF UI Display Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      @media screen and (max-width: 1024px) {
        font-size: 12px;
      }
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #8aa0b1;
      &.activeRelation {
        color: #ffffff;
      }
    }
  }
}
.specInfoContainer {
  width: 300px;
  height: 100%;
  max-height: 589px;
  // padding: 35px;
  // padding-right: 20px;
  padding-top: 33px;
  text-align: left;
  background: #ffffff;
  border-radius: 8px;
  // -webkit-box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2) inset;
  // -moz-box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2) inset;
  // box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2) inset;
  @media only screen and (max-width: 1024px) and (orientation: portrait) {
    border-radius: 0px;
  }
  @media screen and (max-width: 1024px) and (orientation: landscape) {
    border-radius: 0px;
    box-shadow: none;
    border: none;
    border-left: 1px solid lightgrey;
  }
  .specContentBox {
    width: 100%;
    max-height: 90%;
    overflow-y: auto;
    padding-right: 25px;
    padding-left: 30px;
    margin-right: 5px;
    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 8px;
      background: unset;
    }
    &::-webkit-scrollbar-thumb {
      width: 8px;
      // height: 10%;
      border-radius: 8px;
      background: #3287cdbe;
      opacity: 0.7;
    }
    &::-webkit-scrollbar-track {
      border-radius: 8px;
      box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.15);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #3288cd;
      cursor: pointer;
    }
    .specInfoTitle {
      font-family: "SF UI Display Medium", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
      }
      line-height: 30px;
      color: #07141f;
      margin-bottom: 15px;
    }
    .specInfo {
      font-family: "SF UI Display Regular", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      @media screen and (max-width: 1024px) {
        font-size: 15px;
      }
      line-height: 22px;
      color: #07141f;
    }
  }
}
// .priceContainer {
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   margin-bottom: 16px;
//   .specializationPrice {
//     font-family: "SF UI Display Medium", sans-serif;
//     font-style: normal;
//     font-weight: 500;
//     font-size: 18px;

//     line-height: 20px;
//     color: #07141f;
//   }
// }

////step2

.randomDocLabel {
  margin-bottom: 20px;
  padding: 15px;
  background: #f4f9fd;
  border-radius: 8px;
  width: 100%;
  // max-width: 260px;
  display: flex;
  @media screen and (max-width: 1024px) {
    padding: 10px;
  }
  &:hover,
  &:focus {
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 4px 1px #3287cd69;
    -moz-box-shadow: 0px 0px 4px 1px #3287cd69;
    box-shadow: 0px 0px 4px 1px #3287cd69;
  }
  &.randomDocSelected {
    background: #3288cd;
    color: #f8f8f8;
  }

  .doctorTitle {
    height: 100%;
    font-family: "SF UI Display Regular", sans-serif;
    margin: auto 0;
    text-align: center;
    color: #07141f;
    font-style: normal;
    font-weight: 550;
    font-size: 14px;
    line-height: 30px;
    color: #07141f;
    &.activeTitle {
      color: #ffffff;
    }
  }
}

.exactDocLabel {
  // margin-bottom: 20px;
  padding: 15px;
  margin-bottom: 20px;
  background: #f4f9fd;
  border-radius: 8px;
  width: 100%;
  min-height: 90px;
  // max-width: 260px;
  display: flex;
  @media screen and (max-width: 1024px) {
    padding: 10px;
    // padding-right: 10px;
  }
  &:last-child {
    margin-bottom: 10px;
  }
  &:hover,
  &:focus {
    cursor: pointer;
  }
  &.randomTimeSelected {
    background: #3288cd;

    .doctorTitle {
      color: #f8f8f8;
    }
  }
  &.h90 {
    height: 90px;
  }
  .doctorTitle {
    height: 100%;
    font-family: "SF UI Display Regular", sans-serif;
    margin: auto 0;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    color: #07141f;
  }
  .addressTitleContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .addressTitle {
    font-family: "SF UI Display Regular", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    @media screen and (max-width: 1024px) {
      font-size: 11px;
    }
    // text-align: ;
    line-height: 20px;
    color: #25445d;
    &.activeTitle {
      color: white;
    }
  }
  .showMoreImg {
    margin-left: auto;
    transform: rotate(-90deg);
    &.rotate {
      transform: rotate(90deg);
    }
  }
}

////DOCTOR SEARCH CONTAINER
.doctorToggleContainer {
  padding-left: 3px;
  padding-right: 3px;
}
.doctorSearchContainer {
  width: 100%;
  // padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 60px;
  background: #f4f9fd;
  border-radius: 8px;
  // overflow: auto;
  .doctorSearch {
    width: 100%;
    height: 40px;
    padding-left: 38px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: "SF UI Display Medium", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    @media screen and (max-width: 1024px) {
      font-size: 13px;
    }
    line-height: 30px;
    color: #25445d;
    background: #ffffff;
    border-radius: 4px;
    border: none;
    &:focus {
      -webkit-box-shadow: 0px 0px 4px 1px #3287cd69;
      -moz-box-shadow: 0px 0px 4px 1px #3287cd69;
      box-shadow: 0px 0px 4px 1px #3287cd69;
    }
  }
  .searchIcon {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
  }
  .doctorInfoContainer {
    width: 100%;
    // overflow: auto;
    .emptyResult {
      width: 100%;
      height: 100%;
      max-height: 136px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & > div {
        font-family: "Roboto Bold", sans-serif;
        font-size: 18px;
        @media screen and (max-width: 1024px) {
          font-size: 15px;
        }
        margin-top: 15px;
        line-height: 20px;
        text-align: center;
        color: #07141f;
      }
    }
    .doctorInfo {
      padding: 10px;
      background: #ffffff;
      border-radius: 8px;
      display: flex;
      align-items: center;
      @media screen and (max-width: 1024px) {
        padding: 5px;
      }
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      &:hover,
      &:focus {
        cursor: pointer;
        background: #3287cd21;
        transform: scale(1.01);
      }
      & span img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 15px;
      }
      & span {
        font-family: "Roboto Bold", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        @media screen and (max-width: 1024px) {
          font-size: 13px;
        }
        line-height: 20px;
        height: 100%;
        display: flex;
        align-items: center;
        color: #07141f;
      }
      &.selectedDoctor {
        background: #3288cd;
        & span {
          color: white;
        }
      }
    }
  }
}
.scrollContainer {
  position: relative;
  overflow: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 12px;
    border-radius: 8px;
    background: unset;
  }
  &::-webkit-scrollbar-thumb {
    width: 15px;
    // height: 10%;
    border-radius: 8px;
    background: #3287cdbe;
    opacity: 0.7;
  }
  &::-webkit-scrollbar-track {
    border-radius: 8px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.15);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #3288cd;
    cursor: pointer;
  }
}

.timeContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 158px;
  .timePickerContainer {
    background: #f4f9fd;
    border-radius: 8px;
    // border: 1px solid #3288cd;
    height: 100%;
    width: 100%;
    max-width: 262px;
    overflow-y: auto;
    // overflow-y: scroll;
    // overflow: hidden;
    scroll-behavior: smooth;
    // justify-content: ;
    .timePickerItem {
      width: 95%;
      height: 40px;
      margin: 0 auto;
      // background: #ffffff;
      border-radius: 4px;
      // border: 1px solid #3288cd;
      opacity: 0.6;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "SF UI Display Medium", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      @media screen and (max-width: 1024px) {
        font-size: 11px;
      }
      line-height: 17px;
      text-align: center;
      color: #000000;
      cursor: pointer;
      &.isInCenter {
        // background: black;
        // border: 1px solid #3288cd;
        background: #ffffff;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
        font-size: 16px;
        @media screen and (max-width: 1024px) {
          font-size: 13px;
        }
        opacity: 1;
        height: 40px;
      }
      &.isAtBottom {
        opacity: 0.1;
      }
    }
  }
}

///STEP Address
///ADDRESS FORM

.hospitalContainer {
  width: 100%;
  .hospitalListBox {
    position: relative;
    background: #f4f9fd;
    border-radius: 8px;
    min-height: 200px;

    width: 100%;
    padding: 20px;
    padding-top: 10px;
    .notFoundBox {
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .notFoundIcon {
        margin: 0 auto;
        margin-bottom: 20px;
      }
      .notFoundText {
        width: 100%;
        font-family: "SF UI Display Medium", sans-serif;
        font-size: 18px;
        color: black;
        text-align: center;
      }
    }
    .inputBox {
      width: 100%;
      position: relative;
      margin-bottom: 15px;
      .hospitalInput {
        width: 100%;
        border-radius: 8px;
        padding-left: 35px;
        height: 40px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-family: "SF UI Display Regular", sans-serif;
        font-size: 15px;
        border: none;
        background: #ffffff;
        &:focus {
          -webkit-box-shadow: 0px 0px 4px 1px #3287cd69;
          -moz-box-shadow: 0px 0px 4px 1px #3287cd69;
          box-shadow: 0px 0px 4px 1px #3287cd69;
        }
      }
      .searchIcon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 8px;
      }
    }
    .hospitaListItem {
      width: 100%;
      padding: 15px;
      display: flex;
      align-items: center;
      background: #ffffff;
      border-radius: 8px;
      &.selected {
        background: #3288cd;
        &:hover,
        &:focus {
          cursor: pointer;
          background: #3288cd;
        }
      }
      &:hover,
      &:focus {
        cursor: pointer;
        background: #3287cd25;
        transform: scale(1.005);
      }
      .hospitalAddress {
        &.selected {
          color: #ffffff;
        }
      }
      .hospItemImage {
        width: 50px;
        height: 50px;
        margin-right: 15px;
      }
    }
  }
  .hospitalItem {
    width: 100%;
    display: flex;
    background: #f4f9fd;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 10px;
    .showMoreImg {
      margin-left: auto;
      transform: rotate(-90deg);
      &.rotate {
        transform: rotate(90deg);
      }
    }
    .hospitalImage {
      width: 50px;
      height: 50px;
      margin-right: 15px;
    }
    .textContainer {
      // height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .clinicTitle {
        font-family: "SF UI Display Medium", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        // @media screen and (max-width: 1024px) {
        //   font-size: 11px;
        // }
        line-height: 20px;
        color: #07141f;
      }
      .clinicCounts {
        font-family: "SF UI Display Regular", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        // @media screen and (max-width: 1024px) {
        //   font-size: 11px;
        // }
        line-height: 20px;
        color: #07141f;
      }
    }
  }
}

.paymentToggleContainer {
  width: 100%;
  // height: 100%;
  // max-height: 160px;
  & > div:not(:last-child) {
    margin-bottom: 15px;
  }
  .promocodeType,
  .cardType {
    &.choosen {
      background: #3288cd;
      // color: #ffffff;
      & div {
        color: white;
      }
    }
  }
  .promocodeType {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    // padding-top: 10px;
    // padding-bottom: 10px;
    background: #f4f9fd;
    border-radius: 8px;
    & img {
      margin-right: 15px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    .paymentTitle {
      font-family: "UI SF Display Medium", sans-serif;
      font-style: normal;
      // font-weight: 500;
      font-size: 18px;
      @media screen and (max-width: 1024px) {
        font-size: 15px;
      }
      line-height: 30px;
      color: #07141f;
    }
    &:hover,
    &:focus {
      cursor: pointer;
    }
  }
  .cardType {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #f4f9fd;
    border-radius: 8px;
    .cardImageBox {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-right: 15px;
      & img {
        width: 40px;
        height: 40px;
      }
    }

    .paymentTitle {
      font-family: "UI SF Display Medium", sans-serif;
      font-style: normal;
      // font-weight: 500;
      font-size: 20px;
      @media screen and (max-width: 1024px) {
        font-size: 17px;
      }
      line-height: 30px;
      color: #07141f;
    }
    &:hover,
    &:focus {
      cursor: pointer;
    }
  }
}
.checkedIcon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}
.fishkaPaymentBox {
  position: relative;
  width: 100%;
  border-radius: 8px;
  background: rgba(255, 0, 0, 0.555);
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url("../../../style/icons/Fishka/fiskaMainCircle.svg");
    background-repeat: no-repeat;
    background-blend-mode: color-burn;
    background-position: 20% 50%;
    background-size: 50%;
    opacity: 0.2;
  }
  // max-height: 180px;
  .fishkaPaymentInfo {
    position: relative;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    z-index: 2;
    margin-top: 10px;
    height: 100px;
    padding: 10px 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border-radius: 10px;
    background: #f4f9fd25;
    .fishkaAmountError {
      position: absolute;
      top: 8%;
      right: 13%;
      color: red;
      // width: ;
    }
    .fishkaPointInput {
      position: absolute;
      // width: 200px;
      width: 37%;
      height: 50%;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
      font-family: "SF UI Display Medium", sans-serif;
      color: red;
      font-size: 23px;
      @media screen and (max-width: 1024px) {
        font-size: 19px;
      }
      outline: none;
      text-align: center;
      border: none;
      background: white;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    .fishkaIcon {
      position: absolute;
      width: 23px;
      height: 23px;
      border-radius: 50%;
      opacity: 0.6;
      top: 50%;
      transform: translateY(-50%);
      right: 200px;
      z-index: 3;
    }
    .infoText {
      width: 50%;
      // max-width: 270px;
      font-family: "SF UI Display Regular", sans-serif;
      font-size: 18px;
      @media screen and (max-width: 1024px) {
        font-size: 15px;
      }
      color: white;
    }
    .infoAmount {
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
      // width: 200px;
      width: 37%;
      height: 50%;
      background: white;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "SF UI Display Medium", sans-serif;
      color: red;
      font-size: 23px;
      @media screen and (max-width: 1024px) {
        font-size: 19px;
      }
    }
  }
  height: 80%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #f4f9fd;
  &::before {
    opacity: 0.1;
  }
  .fishkaPaymentInfo {
    height: 28%;
    padding: 0 20px;
    .infoText {
      color: black;
    }
    .infoAmount {
      -webkit-box-shadow: 0px 0px 6px 1px rgba(34, 60, 80, 0.12) inset;
      -moz-box-shadow: 0px 0px 6px 1px rgba(34, 60, 80, 0.12) inset;
      box-shadow: 0px 0px 6px 1px rgba(34, 60, 80, 0.12) inset;
    }
    .fishkaPointInput {
      -webkit-box-shadow: 0px 0px 6px 1px rgba(34, 60, 80, 0.12) inset;
      -moz-box-shadow: 0px 0px 6px 1px rgba(34, 60, 80, 0.12) inset;
      box-shadow: 0px 0px 6px 1px rgba(34, 60, 80, 0.12) inset;
    }
    .fishkaIcon {
      right: 190px;
    }
    // min-height: 20;
  }
  .fishkaPaymentInfo:first-child {
    margin-top: 0;
  }
}
.inputPromocodeBox {
  position: relative;
  height: 100%;
  max-height: 180px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding-top: 40px;
  .spinnerBox {
    position: absolute;
    top: 55px;
    right: 30px;
    // transform: translateY(-50%);
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .inputPromocode {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    max-width: 300px;
    padding: 10px;
    margin-bottom: 30px;
    height: 60px;
    background: #f4f9fd;
    border-radius: 8px;
    border: none;
    // padding-left: 20px;
    font-family: "SF UI Display Medium", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    @media screen and (max-width: 1024px) {
      font-size: 21px;
    }
    line-height: 30px;
    text-align: center;
    color: #262e42;
    &::placeholder {
      color: #636c83;
    }
    &:focus::placeholder {
      color: transparent;
    }
  }
}

.bookingPageContainer {
  width: 100%;
  height: 100%;
  max-width: 940px;
  max-height: 689px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    justify-content: space-evenly;
    max-height: 800px;
  }
  .bookingContentContainer {
    width: 100%;
    display: flex;
    height: 100%;
    max-height: 589px;
    justify-content: space-between;
    .animationContainer {
      // -webkit-box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2) inset;
      // -moz-box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2) inset;
      // box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2) inset;
      @media screen and (max-width: 1024px) and (orientation: landscape) {
        border-radius: 0px;
        box-shadow: none;
      }
      &.noPadding {
        padding: 0;
        height: 590px;
        max-height: 596px;
      }
      // margin-right: 20px;
      width: 100%;
      max-width: 620px;
      height: 100%;
      max-height: 589px;
      padding: 30px;
      padding-bottom: 20px;
      padding-top: 30px;
      background: #ffffff;
      border-radius: 8px;
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
        border-radius: 0px;
      }
      @media screen and (max-width: 1024px) {
        padding: 20px;
      }
      .bookingContent {
        position: relative;
        overflow: hidden;
        width: 100%;
        max-width: 620px;
        height: 100%;
        max-height: 589px;
        &.noOverflow {
          overflow: unset;
        }
      }
    }

    // .analysisInfo {
    //   width: 100%;
    //   max-width: 300px;
    //   height: 100%;
    //   max-height: 589px;
    //   background: #ffffff;
    //   border-radius: 8px;
    // }
  }
}
@keyframes stepForwardAnimation {
  from {
    left: 100%;
    opacity: 0;
  }
  to {
    left: 0px;
    opacity: 1;
  }
}

.bookingStepCard {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  animation: stepForwardAnimation 0.3s ease-in-out;
  .bookingStepContent {
    max-height: 430px;
    height: 83%;
    & > div:first-child {
      margin-bottom: 38px;
    }
    & .chooseCard {
      font-family: "SF UI Display Medium", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      @media screen and (max-width: 1024px) {
        font-size: 15px;
      }
      line-height: 20px;
      color: #07141f;
      margin-bottom: 16px;
    }
  }
  .bookingStepButton {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    // @media screen and (max-width: 1024px) {
    //   bottom: 5px;
    //   height: 30px;
    //   .btn {
    //     height: 30px;
    //   }
    // }
    .btn {
      height: 40px;
    }
    @media screen and (max-width: 1024px) {
      height: 30px;
      .btn {
        height: 30px;
      }
    }
  }
}

.bookingStepDoctor {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  animation: stepForwardAnimation 0.3s ease-in-out;
  .bookingStepContent {
    padding-right: 5px;
    max-height: 450px;
    height: 81%;
    overflow-y: auto;
    scrollbar-width: none;
    margin-top: 10px;
    &::-webkit-scrollbar {
      width: 12px;
      border-radius: 8px;
      background: unset;
    }
    &::-webkit-scrollbar-thumb {
      width: 15px;
      // height: 10%;
      border-radius: 8px;
      background: #3287cdbe;
      opacity: 0.7;
    }
    &::-webkit-scrollbar-track {
      border-radius: 8px;
      box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.15);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #3288cd;
      cursor: pointer;
    }
    .chooseDoctor {
      margin-left: 5px;
      font-family: "SF UI Display Medium", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
      }
      line-height: 20px;
      color: #07141f;
      margin-bottom: 10px;
    }
  }
  .bookingStepButton {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    .btn {
      height: 40px;
    }
    @media screen and (max-width: 1024px) {
      height: 30px;
      .btn {
        height: 30px;
      }
    }
  }
}

.bookingStepAddress {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  animation: stepForwardAnimation 0.3s ease-in-out;
  #idprovince > div > div:nth-child(2) > div,
  #iddistrict > div > div:nth-child(2) > div {
    background: white;
  }
  .bookingStepContent {
    .addressFormContainer {
      height: 100%;
    }
    max-height: 455px;
    // height: 100%;
    overflow-y: auto;
    scrollbar-width: none;
    // margin-top: 20px;
    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 8px;
      background: unset;
    }
    &::-webkit-scrollbar-thumb {
      width: 8px;
      // height: 10%;
      border-radius: 8px;
      background: #3287cdbe;
      opacity: 0.7;
    }
    &::-webkit-scrollbar-track {
      border-radius: 8px;
      box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.15);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #3288cd;
      cursor: pointer;
    }
    .chooseDoctor {
      font-family: "SF UI Display Medium", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      @media screen and (max-width: 1024px) {
        font-size: 15px;
      }
      line-height: 20px;
      color: #07141f;
      margin-bottom: 10px;
    }
  }
  .bookingStepButton {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    .btn {
      height: 40px;
    }
    @media screen and (max-width: 1024px) {
      height: 30px;
      .btn {
        height: 30px;
      }
    }
  }
}

.bookingStepConfirmation {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  animation: stepForwardAnimation 0.3s ease-in-out;
  .confirmationContainer {
    max-height: 77%;
    overflow-y: auto;
    padding-right: 5px;
    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 8px;
      background: unset;
    }
    &::-webkit-scrollbar-thumb {
      width: 8px;
      // height: 10%;
      border-radius: 8px;
      background: #3287cdbe;
      opacity: 0.7;
    }
    &::-webkit-scrollbar-track {
      border-radius: 8px;
      box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.15);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #3288cd;
      cursor: pointer;
    }
    .confirmationItem:not(:last-child) {
      margin-bottom: 20px;
    }
    &.lessHeight {
      max-height: 78%;
    }
    .homeConfirmBox {
      width: 100%;
      border-radius: 8px;
      background: #f4f9fd;
      padding: 15px;
      .addressText {
        width: 100%;
        font-family: "SF UI Display Regular", sans-serif;
        font-size: 15px;
        // text-align: right;
        margin-bottom: 10px;
        border-bottom: 1px solid #3287cd25;
        .addressTitle {
          font-family: "SF UI Display Regular", sans-serif;
          font-size: 15px;
          line-height: 18px;
          width: 30%;
          margin-right: 10px;
          color: grey;
          text-transform: uppercase;
        }
      }
    }
    .confirmationItemTitle {
      font-family: "SF UI Display Regular", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }
      line-height: 20px;
      color: #07141f;
      margin-bottom: 10px;
    }
    .confirmationCard {
      padding: 15px;
      display: flex;
      width: 100%;
      background: #f4f9fd;
      border-radius: 8px;
      .userTime {
        @extend .confirmationItemTitle;
        margin-bottom: 0;
      }
      @media screen and (max-width: 1024px) {
        padding: 10px;
      }
    }
    .confirmationSpecialization {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5px;
      height: 120px;
      width: 166px;
      margin-right: 21px;
      background: #f4f9fd;
      border-radius: 8px;
      .specializationName {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        @media screen and (max-width: 1024px) {
          font-size: 14px;
        }
        line-height: 20px;
        text-align: center;
        color: #07141f;
      }
      & img {
        width: 40px;
        height: 40px;
        margin-bottom: 10px;
      }
    }
  }
  .bookingStepButton {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    .btn {
      height: 40px;
    }
    @media screen and (max-width: 1024px) {
      height: 30px;
      .btn {
        height: 30px;
      }
    }
  }
}
.paymentContainer {
  position: absolute;
  bottom: 0;
  height: 102%;
  background: white;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  animation: stepForwardAnimation 0.3s ease-in-out;
  // .analysisStepTools {
  // }
  .analysisStepTools {
    width: 90%;
    margin: 16px auto;
  }
  .fondyContainer {
    height: 88%;
    width: 100%;
    // height: 90%;
    // left: 0;
    // top: 0;
    // position: absolute;
    // animation: stepForwardAnimation 0.3s ease-in-out;
    border-radius: 8px;
    .f-top {
      .f-top-inner {
        display: none;
      }
    }
  }
}

.inputContainer {
  position: relative;
  .inputError {
    color: red;
    position: absolute;
    left: 10px;
    font-family: "SF UI Display Medium", sans-serif;
    font-size: 12px;
    @media screen and (max-width: 1024px) {
      font-size: 9px;
    }
    top: 32px;
  }
  .label {
    width: 100%;
    font-family: "SF UI Display Medium", sans-serif;
    margin-bottom: 10px;
    text-align: center;
    color: #636c83;
    font-size: 16px;
    @media screen and (max-width: 1024px) {
      font-size: 13px;
    }
  }
}
