.addressFormContainer {
  position: absolute;
  bottom: 0;
  // right: 9.5%;
  right: 4px;
  width: 48%;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 10px;
  padding-top: 20px;
  background: #f4f9fd;
  border-radius: 8px;
  z-index: 99999;
  -webkit-box-shadow: 0px 0px 25px -3px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 25px -3px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 25px -3px rgba(34, 60, 80, 0.2);
  &.main {
    position: static;
    width: 100%;
    height: auto;
    overflow-y: unset;
    box-shadow: none;
    border-radius: 0px;
    // min-height: 490px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0px;
  }
  .fieldSimpleSelect {
    .simpleInput {
      background: white;
    }
  }
  .outerButtonBox {
    position: absolute;
    bottom: -25px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    .btn {
      height: 40px;
    }
  }

  & div {
    margin-bottom: 26px;
    margin-top: 4px;
  }
  .addressInput {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    padding-left: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-family: "SF UI Display Regular", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    @media screen and (max-width: 1024px) {
      font-size: 11px;
    }
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #636c83;
    border: none;
    &:last-child {
      margin-bottom: 20px;
    }
  }
  .inlineContainer {
    margin-bottom: 20px;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
  }
  .btn {
    min-height: 40px;
    height: 50px;
    .spinnerBox {
      top: 15px;
    }
    &.secondaryRed {
      height: 50px;
    }
  }
}
