.permissionPopup {
  position: fixed;
  border-radius: 12px;
  top: 20px;
  left: 8%;
  width: 330px;
  height: 150px;
  padding: 10px;
  // background: #3288cd;
  background: white;
  z-index: 999;
  animation: permission 1.5s ease-in-out;
  -webkit-box-shadow: 0px 0px 5px 2px #3287cd69;
  -moz-box-shadow: 0px 0px 5px 2px #3287cd69;
  box-shadow: 0px 0px 5px 2px #3287cd69;
  display: flex;
  justify-content: center;
  align-items: center;
  .triangle {
    position: absolute;
    top: -20px;
    left: 12%;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #3287cd69;
  }
  .permissionText {
    width: 100%;
    text-align: left;
    font-family: "SF UI Display Regular", sans-serif;
    font-size: 18px;
    text-align: center;
    @media screen and (max-width: 1024px) {
      font-size: 17px;
    }
  }
}

@keyframes permission {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
