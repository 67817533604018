.callingModalBox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  min-height: 250px;
  min-width: 350px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  background: white;
  .callingInfo {
    max-width: 80%;
    font-family: "SF UI Display Regular", sans-serif;
    font-size: 20px;
    @media screen and (max-width: 1024px) {
      font-size: 17px;
    }
    margin-bottom: 30px;
  }
  .docImage {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    box-shadow: 0 0 0 #3288cd;
    animation: pulse 2s infinite;
  }
  .callingButtonsBox {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    display: flex;
    justify-content: space-between;
    .toolImage {
      width: 50px;
      height: 50px;
    }
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 #3288cd;
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px #3288cd;
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 #3288cd;
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 #3288cd;
    box-shadow: 0 0 0 0 #3288cd;
  }
  70% {
    -moz-box-shadow: 0 0 0 10px #3288cd;
    box-shadow: 0 0 0 10px #3288cd;
  }
  100% {
    -moz-box-shadow: 0 0 0 0 #3288cd;
    box-shadow: 0 0 0 0 #3288cd;
  }
}
