.dot-carousel {
  position: relative;
  left: -9999px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #3288cd;
  color: #3288cd;
  box-shadow: 9984px 0 0 0 #3288cd, 9999px 0 0 0 #3288cd, 10014px 0 0 0 #3288cd;
  animation: dotCarousel 1.5s infinite linear;
}

@keyframes dotCarousel {
  0% {
    box-shadow: 9984px 0 0 -1px #3288cd, 9999px 0 0 1px #3288cd,
      10014px 0 0 -1px #3288cd;
  }
  50% {
    box-shadow: 10014px 0 0 -1px #3288cd, 9984px 0 0 -1px #3288cd,
      9999px 0 0 1px #3288cd;
  }
  100% {
    box-shadow: 9999px 0 0 1px #3288cd, 10014px 0 0 -1px #3288cd,
      9984px 0 0 -1px #3288cd;
  }
}
