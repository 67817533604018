.pageContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.cardInfoContainer {
  // overflow-y: auto;
  // height: 100vh;
  height: 100%;
  // max-height: 690px;
  width: 100%;
  max-width: 940px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;
  @media only screen and (max-width: 1024px) and (orientation: portrait) {
    max-height: 100%;
  }
  #idgender > div > div:nth-child(1) > div,
  #idrelationShip > div > div:nth-child(1) > div,
  #idaddress > div > div:nth-child(1) > div {
    background: #f4f9fd;
    font-size: 15px;
    display: flex;
    align-items: center;
  }
  #idaddress > div > div.wrapperOptions {
    top: -200px;
    & .options {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      margin-top: 2px;
      box-shadow: 0px -2px 4px 1px #3287cd69;
    }
  }
  #idprovince > div > div:nth-child(2) > div {
    background: white;
  }
  #iddistrict > div > div:nth-child(2) > div {
    background: white;
  }
}
.cardPageTitleContainer {
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1024px) and (orientation: portrait) {
    margin: 10px 0px;
  }
  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    margin: 5px 0px;
  }
  .deleteBox {
    margin-left: auto;
    // width: 60px;
    // height: 100%;
  }
  .imageBox {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    margin-right: 20px;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2) inset;
    -moz-box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2) inset;
    box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2) inset;
    & img {
      border-radius: 50%;
      width: 60px;
      height: 60px;
    }
  }
  .cardInfoTextBox {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .cardInfoTitle {
      font-family: "Roboto Bold", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      @media screen and (max-width: 1024px) {
        font-size: 25px;
      }
      line-height: 36px;
      color: #272d2d;
    }
    .cardRelationTitle {
      font-family: "SF UI Display Regular", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      // @media screen and (max-width: 1024px) {
      //   font-size: 11px;
      // }
      line-height: 30px;
      display: flex;
      align-items: center;
      color: #89a0b1;
    }
  }
}

.cardContentContainer {
  width: 100%;
  // overflow-y: auto;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
  max-height: 610px;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  @media screen and (max-width: 1024px) {
    // max-height: 500px;
    padding: 15px;
  }
  @media (max-width: 768px), screen and (orientation: portrait) {
    max-height: 90%;
    border-radius: 0px;
  }
  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    border-radius: 0px;
    box-shadow: none;
  }
  -webkit-box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2) inset;
  -moz-box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2) inset;
  box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2) inset;
  .emptyList {
    position: absolute;
    // width: 100%;
    top: 100px;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    @media screen and (max-width: 1024px) {
      font-size: 22px;
    }
    font-family: "SF UI Display Medium", sans-serif;
    opacity: 0.5;
  }
  //   max-width: ;
  .historyModeContainer {
    width: 100%;
    height: 100%;
    max-height: 471px;
    // @media screen and (max-width: 1024px) {
    //   height: 80%;
    // }
    @media screen and (max-width: 1024px) and (orientation: portrait) {
      height: 94%;
      max-height: 550px;
      // max-height: 461px;
      // max-height: 500%;
    }
  }
  .absoluteCenter {
    position: absolute;
    // top: 0;
    // left: 0;
    // bottom: 0;
    // right: 0;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    transform: translate(-50%, -50%);
  }
}

.modeContainer {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  background: #f4f9fd;
  border-radius: 8px;
  margin-bottom: 20px;
  @media screen and (max-width: 1024px) {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 1024px) and (orientation: portrait) {
    padding: 7px;
  }
  .modeItem {
    height: 30px;
    // width: 100%;
    width: 48%;
    // max-width: 224px;
    transition: all 0.3s ease-in;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1024px) {
      height: 30px;
    }
    &:hover,
    &:focus {
      cursor: pointer;
    }
    &.isActive {
      background: #ffffff;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.04);
      border-radius: 4px;
    }
  }
}
.editFormContainer {
  height: 100%;
  max-height: 500px;
  padding-bottom: 15px;
  @media screen and (max-width: 1024px) and (orientation: portrait) {
    max-height: 570px;
    padding-bottom: 0;
  }
  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    padding-bottom: 0;
    max-height: 451px;
  }
}
form {
  height: 100%;
  // padding-top: 40px;
  // max-height: 451px;
  // padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: unset;
  // padding-bottom: 15px;
  @media screen and (max-width: 1024px) {
    height: calc(100% - 50px);
  }
  .btn {
    height: 50px;
  }
}

.anamnezContainer {
  width: 100%;
  min-height: 100px;
  margin-bottom: 20px;
  position: relative;
  .anamnezContainerTitle {
    margin-bottom: 10px;
    font-family: "SF UI Display Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    @media screen and (max-width: 1024px) {
      font-size: 15px;
    }
    line-height: 30px;
    color: #07141f;
  }
  .anamnezListContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .anamnesisSection {
      width: 100%;
      max-width: 420px;
      background: #f4f9fd;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .anamnesisItem {
        width: 100%;
        padding-top: 17px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ffffff;
        @media screen and (max-width: 1024px) {
          padding-top: 8px;
          padding-left: 10px;
          padding-right: 10px;
          padding-bottom: 5px;
        }
        .anamnezTitle {
          font-family: "SF UI Display Medium", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          @media screen and (max-width: 1024px) {
            font-size: 11px;
          }
          line-height: 20px;
          color: #07141f;
          margin-bottom: 10px;
        }
        .anamnezContent {
          font-family: "SF UI Display Regular", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          @media screen and (max-width: 1024px) {
            font-size: 11px;
          }
          line-height: 20px;
          color: #07141f;
        }
      }
    }
  }
}

.confirmCardContainer {
  width: 100%;
  // height: 100%;
  min-height: 150px;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 12px;
  .confirmText {
    font-family: "SF UI Display Regular", sans-serif;
    font-size: 23px;
    @media screen and (max-width: 1024px) {
      font-size: 19px;
    }
    text-align: center;
  }
  .confirmButtons {
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: flex;
    width: 200px;
    justify-content: space-between;
    .btn {
      max-width: 90px;
      height: 40px;
    }
  }
}
