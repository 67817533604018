.pageContainer {
  width: 100%;
  height: 100%;
  // grid-template-rows: 60px 1fr;
  // grid-template-columns: 1fr;
  display: flex;
  flex-direction: column;
  &.small {
    max-width: 300px;
    @media screen and (max-width: 1024px) and (orientation: portrait) {
      width: 0;
    }
    &.dnone {
      width: 0;
    }
  }
}

.pageHeaderBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // height: ;
  &.loading {
    justify-content: center;
    min-height: 60px;
  }
}
.chatContainer {
  width: 100%;
  height: calc(100% - 65px);
  // max-width: 900px;
  background: #ffffff;
  border-radius: 8px;
  // padding-top: 2.8%;
  padding-bottom: 40px;
  padding-top: 10px;
  position: relative;
  outline: none;
  .errorText {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 18px;
    opacity: 0.8;
    width: 100%;
    text-align: center;
    color: red;
  }
  .connectionBox {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .loadingText {
      font-family: "Roboto", sans-serif;
      font-weight: 600;
      font-size: 18px;
      opacity: 0.8;
      color: #3288cd;
      margin-right: 10px;
    }
  }
  &.dnone {
    display: none;
  }
  &.small {
    height: 100%;
    max-width: 380px;
    border-radius: 0;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2) inset;
    -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2) inset;
    box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2) inset;
    @media screen and (max-width: 1024px) and (orientation: portrait) {
      display: none;
    }
  }

  .dragNdrop {
    position: absolute;
    width: 100%;
    height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #3287cd2a;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    .dropFileContainer {
      width: 45%;
      height: 45%;
      border-radius: 12px;
      background: #3287cd6c;
      border: 3px dashed #3288cd;
      display: flex;
      justify-content: center;
      align-items: center;
      .dropFileText {
        text-align: center;
        font-family: "SF UI Display Medium", sans-serif;
        font-size: 20px;
        @media screen and (max-width: 1024px) {
          font-size: 17px;
        }
        line-height: 20px;
        color: #3288cd;
        opacity: 0.7;
      }
    }
  }

  .closePanel {
    width: 100%;
    height: 45px;
    position: relative;
    img {
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .supportTitle {
    font-family: "SF UI Display Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    @media screen and (max-width: 1024px) {
      font-size: 15px;
    }
    line-height: 20px;
    text-align: center;
    // margin-top: 20px;
    color: #212223;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .doctorChatHeader {
    // padding-top: 10px;
    padding-bottom: 10px;
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 30px;
    .previousBox {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      &:hover {
        background: lightgrey;
      }
    }
    .doctorImage {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin-right: 20px;
    }
    .doctorInfo {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .doctorName {
        font-family: "SF UI Display Medium", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        @media screen and (max-width: 1024px) {
          font-size: 15px;
        }
        line-height: 20px;
        color: #272d2d;
      }
      .doctorSpec {
        font-family: "SF UI Display Medium", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        @media screen and (max-width: 1024px) {
          font-size: 9px;
        }
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #89a0b1;
      }
    }
  }
}
.textareaChatContainer {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  width: 98%;
  display: flex;
  background: white;
  padding-top: 5px;
  justify-content: space-evenly;
  align-items: center;
  height: 65px;
  .textAreaBox {
    position: relative;
    width: 70%;
    height: auto;
    .prevAudioTools {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      background: #f4f9fd;
      height: 50px;
      margin: auto 0px;
    }
    .recordedAudioBox {
      width: 300px;
      height: 60px;
      border-radius: 8px;
      background: #3288cd;
    }
    .recordingTime {
      position: absolute;
      background: transparent;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      .redDot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: red;
        margin-right: 8px;
      }
      .time {
        text-align: center;
        width: 64px;
        font-family: "SF UI Display Regular", sans-serif;
      }
    }
    .chatInput {
      margin-right: 8px;
      resize: none;
      max-height: 125px;
      overflow-y: auto;
      display: flex;
      align-items: center;
      width: 100%;
      background: #f6f7fb;
      border-radius: 8px;
      font-family: "SF UI Display Regular", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      max-height: 50px;
      margin: auto 0px;
      @media screen and (max-width: 1024px) {
        font-size: 15px;
      }
      line-height: 20px;
      padding: 15px;
      border: none;
      padding-left: 10px;
      &::-webkit-scrollbar {
        width: 12px;
        border-radius: 8px;
        background: unset;
      }
      &::-webkit-scrollbar-thumb {
        width: 15px;
        // height: 10%;
        border-radius: 8px;
        background: #3287cdbe;
        opacity: 0.7;
      }
      &::-webkit-scrollbar-track {
        border-radius: 8px;
        box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.15);
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #3288cd;
        cursor: pointer;
      }
    }
  }
  .chatTools {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // height: 100%;
    width: 20%;
    @media screen and (max-width: 1024px) {
      width: auto;
    }
    .attachmentImageBox {
      // margin-left: 20px;
      position: relative;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      // background: #f4f9fd;
      // border-radius: 50%;
      // &:hover,
      // &:focus {
      //   background: #dde6ee;
      // }
      // .chatAttachment {
      //   width: 30px;
      //   height: 30px;
      // }
      .variantsContainer {
        position: absolute;
        top: -100px;
        right: 0;
        width: 170px;
        border-radius: 8px;
        padding: 7px;
        z-index: 9999;
        background: #f4f9fd;
        .variant {
          font-family: "SF UI Display Regular", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          @media screen and (max-width: 1024px) {
            font-size: 13px;
          }
          line-height: 20px;
          text-align: center;
          padding: 10px;
          margin-bottom: 5px;
          &:hover,
          &:focus {
            cursor: pointer;
            background: white;
          }
        }
      }
    }
  }
}
.chatArea {
  // height: 83.5%;
  height: calc(100% - 50px);
  &.small {
    height: calc(100% - 105px);
  }
  position: relative;
  @media screen and (max-width: 1024px) {
    height: 85.5%;
  }
  .absoluteDate {
    display: none;
    font-family: "SF UI Display Regular", sans-serif;
    font-size: 15px;
    @media screen and (max-width: 1024px) {
      font-size: 14px;
    }
    opacity: 0.8;
    // display: flex;
    width: auto;
    padding: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    margin: 0 auto;
    // background: #f4f9fd;
    background: white;
    color: rgba(37, 68, 93, 1);
    min-width: 130px;
    // margin-bottom: 10px;
    // margin-top: 10px;
    &.isVisible {
      position: absolute;
      // left: 50%;
      color: #3288cd;
      background: white;
      left: 49.5%;
      top: 10px;
      transform: translateX(-50%);
      display: flex;
      opacity: 1;
      z-index: 99;
    }
  }
  .scrollArea {
    padding-right: 20px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .date {
      font-family: "SF UI Display Regular", sans-serif;
      font-size: 15px;
      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }
      opacity: 0.7;
      display: flex;
      width: auto;
      padding: 5px;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 10px;
      margin: 0 auto;
      background: #f4f9fd;
      color: rgba(37, 68, 93, 1);
      margin-bottom: 10px;
      margin-top: 10px;
      &.notVisible {
        position: absolute;
        left: 50%;
        top: 10px;
        transform: translateX(-50%);
      }
    }
    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 8px;
      background: unset;
    }
    &::-webkit-scrollbar-thumb {
      width: 10px;
      // height: 10%;
      border-radius: 8px;
      background: #3287cdbe;
      opacity: 0.7;
    }
    &::-webkit-scrollbar-track {
      border-radius: 8px;
      box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.15);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #3288cd;
    }
  }
  //510
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
    background: unset;
  }
  &::-webkit-scrollbar-thumb {
    width: 10px;
    // height: 10%;
    border-radius: 8px;
    background: #3287cdbe;
    opacity: 0.7;
  }
  &::-webkit-scrollbar-track {
    border-radius: 8px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.15);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #3288cd;
  }
}

.ordinaryMessageBox {
  position: relative;
  padding: 10px;
  padding-bottom: 25px;
  width: auto;
  min-width: 100px;
  max-width: 340px;
  height: auto;
  background: #3288cd;
  border-radius: 8px;
  margin-bottom: 20px;
  // border: 1px solid #666;
  // border-radius: 30px;
  -webkit-box-shadow: 1px 1px 1px #f4f9fd;
  -moz-box-shadow: 1px 1px 1px #f4f9fd;
  box-shadow: 1px 1px 1px #f4f9fd;
  &.me {
    align-self: flex-end;
    &::before {
      content: " ";
      position: absolute;
      width: 0;
      height: 0;
      right: 10px;
      bottom: -12px;
      border: 6px solid;
      border-color: #3288cd #3288cd transparent transparent;
    }
  }
  &.you {
    align-self: flex-start;
    background: #f4f9fd;
    &::before {
      content: " ";
      position: absolute;
      width: 0;
      height: 0;
      left: 10px;
      bottom: -12px;
      border: 6px solid;
      border-color: #f4f9fd #f4f9fd transparent transparent;
      transform: rotate(-90deg);
    }
  }
  &.you {
    .messageInfo {
      opacity: 0.8;
      position: absolute;
      bottom: 4px;
      right: 5%;
      display: flex;
      justify-content: space-between;
      // width: 12%;
      .messageTime {
        font-family: "SF UI Display Regular", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        @media screen and (max-width: 1024px) {
          font-size: 13px;
        }
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: right;
        color: black;
        margin-right: 7px;
      }
      .messageReaded {
        display: none;
      }
    }
  }
  &.you .messageText,
  &.you .messageLink {
    color: black;
  }
  .messageLink {
    font-family: "SF UI Display Regular", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    @media screen and (max-width: 1024px) {
      font-size: 15px;
    }
    line-height: 25px;
    color: #ffffff;
    letter-spacing: 0.05em;
    word-break: break-all;
    margin-bottom: 10px;
    // margin-bottom: 3px;
    &:hover,
    &:focus {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .messageText {
    word-break: break-all;
    margin-bottom: 5px;
    font-family: "SF UI Display Regular", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    @media screen and (max-width: 1024px) {
      font-size: 15px;
    }
    line-height: 20px;
    color: #ffffff;
    letter-spacing: 0.05em;
  }
  .messageInfo {
    opacity: 0.8;
    position: absolute;
    bottom: 4px;
    right: 5%;
    display: flex;
    justify-content: space-between;
    // width: 12%;
    .messageTime {
      font-family: "SF UI Display Regular", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      @media screen and (max-width: 1024px) {
        font-size: 13px;
      }
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: right;
      color: #ffffff;
      margin-right: 7px;
    }
    .messageReaded {
      &.readed {
        width: 20px;
        height: 20px;
      }
      &.sent {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.imageMessageBox {
  position: relative;
  padding: 10px;
  padding-bottom: 20px;
  padding-left: 0;
  padding-top: 0;
  padding-right: 5px;
  width: 100%;
  max-width: 340px;
  height: auto;
  background: #3288cd;
  border-radius: 8px;
  margin-bottom: 20px;
  padding-top: 5px;
  padding-left: 5px;
  &.me {
    align-self: flex-end;
    &::before {
      content: " ";
      position: absolute;
      width: 0;
      height: 0;
      right: 10px;
      bottom: -12px;
      border: 6px solid;
      border-color: #3288cd #3288cd transparent transparent;
    }
  }
  &.you {
    align-self: flex-start;
    background: #f4f9fd;
    &::before {
      content: " ";
      position: absolute;
      width: 0;
      height: 0;
      left: 10px;
      bottom: -12px;
      border: 6px solid;
      border-color: #f4f9fd #f4f9fd transparent transparent;
      transform: rotate(-90deg);
    }
  }
  &.you {
    .messageInfo {
      opacity: 0.8;
      position: absolute;
      bottom: 4px;
      right: 5%;
      display: flex;
      justify-content: space-between;
      // width: 12%;
      .messageTime {
        font-family: "SF UI Display Regular", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        @media screen and (max-width: 1024px) {
          font-size: 13px;
        }
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: right;
        color: black;
        margin-right: 7px;
      }
      .messageReaded {
        display: none;
      }
    }
  }
  &.you .messageText,
  &.you .imageName {
    color: black;
  }
  .messageImage {
    // max-width: 500px;
    // margin: 0 auto;
    max-width: 100%;
    min-height: 100px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    margin-bottom: 5px;
  }
  .imageName {
    padding-left: 20px;
    font-family: "SF UI Display Regular", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    @media screen and (max-width: 1024px) {
      font-size: 11px;
    }
    line-height: 20px;
    color: white;
  }
  .videoMessage {
    max-width: 340px;
    margin-bottom: 5px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    // max-height: 400px;
  }
  .messageInfo {
    opacity: 0.8;
    position: absolute;
    bottom: 4px;
    right: 5%;
    display: flex;
    justify-content: space-between;
    // width: 12%;
    .messageTime {
      font-family: "SF UI Display Regular", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      @media screen and (max-width: 1024px) {
        font-size: 13px;
      }
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: right;
      color: #ffffff;
      margin-right: 7px;
    }
    .messageReaded {
      &.readed {
        width: 20px;
        height: 20px;
      }
      &.sent {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.videoMessageBox {
  position: relative;
  padding-bottom: 20px;
  width: auto;
  max-width: 360px;
  height: auto;
  background: #3288cd;
  border-radius: 8px;
  margin-bottom: 20px;
  &.me {
    align-self: flex-end;
    &::before {
      content: " ";
      position: absolute;
      width: 0;
      height: 0;
      right: 10px;
      bottom: -12px;
      border: 6px solid;
      border-color: #3288cd #3288cd transparent transparent;
    }
  }
  &.you {
    align-self: flex-start;
    background: #f4f9fd;
    &::before {
      content: " ";
      position: absolute;
      width: 0;
      height: 0;
      left: 10px;
      bottom: -12px;
      border: 6px solid;
      border-color: #f4f9fd #f4f9fd transparent transparent;
      transform: rotate(-90deg);
    }
  }
  &.you {
    .messageInfo {
      opacity: 0.8;
      position: absolute;
      bottom: 4px;
      right: 5%;
      display: flex;
      justify-content: space-between;
      // width: 12%;
      .messageTime {
        font-family: "SF UI Display Regular", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        @media screen and (max-width: 1024px) {
          font-size: 13px;
        }
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: right;
        color: black;
        margin-right: 7px;
      }
      .messageReaded {
        display: none;
      }
    }
  }
  &.you .messageText {
    color: black;
  }
  .messageImage {
    // max-width: 500px;
    min-height: 100px;
    max-width: 360px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    margin-bottom: 5px;
  }
  .videoMessage {
    // max-width: 340px;
    margin-bottom: 5px;
    max-width: 360px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    // max-height: 400px;
  }
  .messageInfo {
    opacity: 0.8;
    position: absolute;
    bottom: 4px;
    right: 5%;
    display: flex;
    justify-content: space-between;
    // width: 12%;
    .messageTime {
      font-family: "SF UI Display Regular", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      @media screen and (max-width: 1024px) {
        font-size: 13px;
      }
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: right;
      color: #ffffff;
      margin-right: 7px;
    }
    .messageReaded {
      &.readed {
        width: 20px;
        height: 20px;
      }
      &.sent {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.audioMessageBox {
  position: relative;
  padding: 10px;
  padding-bottom: 30px;
  margin-bottom: 20px;
  width: 260px;
  // height: 110px;
  border-radius: 8px;
  &.recordingFormat {
    // padding-bottom: 5px;
    // width: 230px;
    // margin-left: 5px;
    width: 100%;
    padding: 5px;
    margin: 0px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    .statusBox {
      height: 30px;
    }
    .animationBox {
      height: 20px;
      .line {
        max-height: 20px;
      }
    }
    .anim {
      height: 20px;
      .lineBox {
        height: 20px;
        .line {
          max-height: 20px;
        }
      }
    }
    .MuiSvgIcon-root {
      width: 40px;
      height: 40px;
    }
    .wave {
      height: 20px;
    }
  }
  &.me {
    align-self: flex-end;
    background: #3288cd;
    color: white;
  }
  &.you {
    align-self: flex-start;
    background: #f4f9fd;
    .messageInfo {
      opacity: 0.8;
      position: absolute;
      bottom: 4px;
      right: 5%;
      display: flex;
      justify-content: space-between;
      // width: 12%;
      .messageTime {
        font-family: "SF UI Display Regular", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        @media screen and (max-width: 1024px) {
          font-size: 13px;
        }
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: right;
        color: black;
        margin-right: 7px;
      }
      .messageReaded {
        display: none;
      }
    }
  }
  .playBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .statusBox {
    height: 40px;
    width: 180px;
  }
  .audioDuration {
    width: 100%;
    text-align: left;
    font-family: "SF UI Display Regular", sans-serif;
  }
  .wave {
    position: relative;
    width: 100%;
    height: 30px;
    .line {
      // height: 100%;
      width: 6px;
      // background: #89a0b1;
      background: transparent;
      border-radius: 2px;
      max-height: 30px;
      min-height: 8px;
      &.grey {
        // background: grey;
        background: #3287cd2c;
      }
      &.blue {
        background: #3288cd;
      }
      // height: random(30) + px;
    }
  }
  .animationBox {
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
  }
  .anim {
    position: absolute;
    display: flex;
    align-items: center;
    width: 0px;
    height: 30px;
    // animation: audio linear;
    // animation-play-state: paused;
    // background-color: #3288cd;
    background: transparent;
    overflow: hidden;
    overflow-x: hidden;
    &.addAnim {
      animation: audio linear;
    }
    .lineBox {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 180px;
      height: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .line {
        width: 6px;
      }
    }
  }
  .messageInfo {
    opacity: 0.8;
    position: absolute;
    bottom: 4px;
    right: 5%;
    display: flex;
    justify-content: space-between;
    // width: 12%;
    .messageTime {
      font-family: "SF UI Display Regular", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      @media screen and (max-width: 1024px) {
        font-size: 13px;
      }
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: right;
      color: #ffffff;
      margin-right: 7px;
    }
    .messageReaded {
      &.readed {
        width: 20px;
        height: 20px;
      }
      &.sent {
        width: 16px;
        height: 16px;
      }
    }
  }
}

#audioContainer > div.playBox > div > div.wave > div.animationBox > div {
  // background: white;
  // background-color: white;
  width: 5px;
}
#audioContainer > div.playBox > div > div.wave > div.anim.addAnim > div > div {
  background: #3287cd9d;
  background-color: #3287cd9d;
  width: 5px;
}

.fileinput {
  width: 0;
  height: 0;
  opacity: 0;
}
.mediaInput {
  width: 0;
  height: 0;
  opacity: 0;
}
.chatPicture {
  max-width: 90vw;
  max-height: 90vh;
}

@keyframes audio {
  from {
    width: 0px;
    overflow-x: hidden;
  }
  to {
    width: 170px;
  }
}
