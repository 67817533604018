.navbarContainer {
  // z-index: 999;
  position: relative;
  width: 100%;
  max-width: 1260px;
  // height: 60px;
  height: 100%;
  // margin-bottom: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 27px;
  background: #ffffff;
  border-radius: 8px;
  // -webkit-box-shadow: 0px 0px 2px 0px rgba(34, 60, 80, 0.2) inset;
  // -moz-box-shadow: 0px 0px 2px 0px rgba(34, 60, 80, 0.2) inset;
  // box-shadow: 0px 0px 2px 0px rgba(34, 60, 80, 0.2) inset;
  @media (max-width: 768px), screen and (orientation: portrait) {
    border-radius: 0px;
  }
  @media only screen and (max-device-width: 1024px) and (orientation: landscape) {
    border-radius: 0px;
    border: none;
    box-shadow: none;
    // border-bottom: 1px solid #5295cc54;
  }
  .toolBox {
    display: flex;
    width: 100%;
    @media (max-width: 768px), screen and (orientation: portrait) {
      flex-direction: row-reverse;
      justify-content: space-evenly;
    }
    .languageBox {
      position: relative;
      // margin: 0 auto;
      // padding-left: 4px;
      // height: 100%;
      width: 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #f4f9fd;
      border-radius: 8px;
      margin-right: 10px;
      .langIcon {
        // border: 1px solid #3288cd;
        border-radius: 4px;
        -webkit-box-shadow: 0px 0px 6px -2px #3288cd;
        -moz-box-shadow: 0px 0px 6px -2px #3288cd;
        box-shadow: 0px 0px 6px -2px #3288cd;
      }
    }
  }
  .navbarButton {
    width: 100%;
    max-width: 128px;
  }
  .searchBox {
    position: relative;
    width: 100%;
    max-width: 70%;
    margin-right: 20px;
    .searchIcon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
      z-index: 9999;
    }
    .symptomsLoadingBox {
      position: absolute;
      right: 10px;
      height: 100%;
      width: 40px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .searchInput {
    width: 100%;
    position: relative;
    // max-width: 610px;
    height: 40px;
    padding-left: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: "SF UI Display Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    background: #f4f9fd;
    &:focus {
      -webkit-box-shadow: 0px 0px 4px 1px #3287cd69;
      -moz-box-shadow: 0px 0px 4px 1px #3287cd69;
      box-shadow: 0px 0px 4px 1px #3287cd69;
    }
    @media screen and (max-width: 1024px) {
      font-size: 13px;
    }
    line-height: 30px;
    color: #07141f;
    // background: #f6f7fb;
    border-radius: 8px;
    border: none;
  }
  .closeSearchIcon {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .buttonsContainer {
    display: flex;
    & .navButton {
      width: 140px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      outline: unset;
      border: none;
      background: #3288cd;
      border-radius: 8px;
      span {
        font-family: "SF UI Display Medium", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        @media screen and (max-width: 1024px) {
          font-size: 15px;
        }
        line-height: 14px;
        color: #ffffff;
        & img {
          margin-right: 10px;
        }
      }
      &:hover,
      &:focus {
        cursor: pointer;
      }
    }
    & .navButton:not(:last-child) {
      margin-right: 20px;
    }
  }
  .userInfoContainer {
    width: 100%;
    max-width: 241px;
    z-index: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    // margin-left: 40px;
    color: #07141f;
    @media only screen and (max-device-width: 1024px) and (orientation: portrait) {
      max-width: 220px;
      // border-bottom: 1px solid #5295cc54;
    }
    &:hover,
    &:focus {
      cursor: pointer;
    }
    & .userImage {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 15px;
      background: #f4f9fd;
    }

    .dropImageContainer {
      position: absolute;
      height: 100%;
      width: 23px;
      display: flex;
      align-items: center;
      top: 0px;
      right: 24px;
      .dropImage {
        width: 13px;
        height: 13px;
        &.rotate {
          transform: rotate(-180deg);
        }
      }
    }
    .userNameMain {
      font-family: "SF UI Display Regular", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      width: 160px;
      @media screen and (max-width: 1024px) {
        font-size: 13px;
      }
      line-height: 20px;
      margin-right: auto;
    }
  }
}
@keyframes dropWindow {
  from {
    top: -690px;
    opacity: 0;
  }
  to {
    top: 80px;
    opacity: 1;
  }
}
.dropDownContainer {
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  max-width: 300px;
  // min-height: 75vh;
  height: 710px;
  max-height: 77vh;
  // height: 100vh;
  // max-height: 690px;
  // max-height: 75.6vh;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 67px;
  top: 80px;
  right: 0px;
  background: #ffffff;
  border-radius: 8px;
  background: white;
  padding-bottom: 10px;
  animation: dropWindow 0.3s ease-in-out;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  @media screen and (max-width: 1024px) and (orientation: portrait) {
    top: 70px;
  }
  @media screen and (max-width: 1024px) and (orientation: landscape) {
    max-height: 80vh;
  }
  .languageContainer {
    display: flex;
    width: 100%;
    max-width: 200px;
    padding: 10px;
    justify-content: space-between;
    margin-bottom: 20px;
    .language {
      width: 40px;
      height: 40px;
      font-size: 20px;
      @media screen and (max-width: 1024px) {
        font-size: 17px;
      }
      font-weight: 550;
      line-height: 25px;
      background: #f6f7fb;
      color: #07141f;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      &:hover,
      &:focus {
        cursor: pointer;
        -webkit-box-shadow: 0px 0px 4px 1px #3287cd69;
        -moz-box-shadow: 0px 0px 4px 1px #3287cd69;
        box-shadow: 0px 0px 4px 1px #3287cd69;
      }
      &.activeLang {
        background: #3288cd;
        color: #ffffff;
        pointer-events: none;
      }
    }
  }
  .dropDownImage {
    z-index: 9999;
    width: 120px;
    height: 120px;
    position: absolute;
    left: 50%;
    border-radius: 50%;
    top: -60px;
    transform: translateX(-50%);
    background: #f4f9fd;
  }
  .dropMenuItem {
    font-family: "SF UI Display Medium";
    background: #f6f7fb;
    border-radius: 8px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 5px;
    margin-bottom: 20px;
    -webkit-box-shadow: 0px 0px 6px -2px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 6px -2px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 6px -2px rgba(34, 60, 80, 0.2);
    .dropTitle {
      max-width: 200px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      margin-bottom: 8px;
      line-height: 15px;
      color: #8aa0b1;
    }
    .dropInfo {
      font-size: 15px;
      line-height: 20px;
      color: #262e42;
    }
  }
  .SubscriptionMenuItem {
    @extend .dropMenuItem;
    position: relative;
    background: #f6f7fb;
    border-radius: 8px;
    padding-left: 20px;
    padding-bottom: 15px;
    padding-top: 15px;
    @media screen and (max-width: 1024px) and (orientation: landscape) {
      // border: none;
      padding: 10px;
    }
    &:hover,
    &:focus {
      cursor: pointer;
      -webkit-box-shadow: 0px 0px 4px 1px #3287cd69;
      -moz-box-shadow: 0px 0px 4px 1px #3287cd69;
      box-shadow: 0px 0px 4px 1px #3287cd69;
    }
    .openIcon {
      position: absolute;
      right: 15px;
      top: 40%;
      transform: translateY(-50%);
      transform: rotate(-180deg);
    }
    .dropInfo,
    .dropTitle {
      font-size: 14px;
      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }
      line-height: 20px;
    }
    .dropInfo {
      margin-bottom: 10px;
    }
  }
}
.medcardsBox {
  position: absolute;
  width: 100%;
  max-width: 300px;
  height: 100vh;
  z-index: 10;
  // height: auto;
  // max-height: 690px;
  height: 705px;
  max-height: 77vh;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 30px;
  overflow-y: auto;
  top: 80px;
  right: 0px;
  background: #ffffff;
  border-radius: 8px;
  background: white;
  padding-bottom: 10px;
  animation: subscriptionList 0.3s ease-in-out;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  @media screen and (max-width: 1024px) and (orientation: portrait) {
    top: 70px;
    // max-height: 576px;
    max-height: 715px;
    height: 695px;
  }
  @media screen and (max-width: 1024px) and (orientation: landscape) {
    top: 70px;
    // max-height: 576px;
    max-height: 715px;
    height: 625px;
  }
  .listTool {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .title {
      align-self: center;
      justify-self: center;
      width: 100%;
      text-align: center;
      font-family: "SF UI Display Medium";
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }
      line-height: 20px;
    }
  }
  .inputContainer {
    height: 100%;
    max-height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // padding-top: 40px;
    .inputPromocode {
      margin: 0 auto;
      text-align: center;
      width: 100%;
      max-width: 300px;
      padding: 10px;
      margin-bottom: 30px;
      height: 60px;
      background: #f4f9fd;
      border-radius: 8px;
      border: none;
      // padding-left: 20px;
      font-family: "SF UI Display Medium", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      @media screen and (max-width: 1024px) {
        font-size: 21px;
      }
      line-height: 30px;
      text-align: center;
      color: #262e42;
      &::placeholder {
        color: #636c83;
      }
      &:focus::placeholder {
        color: transparent;
      }
    }
  }
  .cardBox {
    width: 100%;
    min-height: 400px;
    // max-height: 80%;
    max-height: 92%;
    overflow-y: auto;
    padding: 4px;
    position: relative;
    @media screen and (max-width: 1024px) {
      max-height: 90%;
    }
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 2px;
      background: unset;
    }
    &::-webkit-scrollbar-thumb {
      width: 5px;
      height: 10%;
      border-radius: 2px;
      background: #3288cd;
    }
    // .userCard:not(:last-child) {
    //   margin-bottom: 20px;
    // }
    .cardHref {
      // height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .userCard {
        width: 100%;
        max-width: 260px;
        max-height: 90px;
        padding: 15px;
        display: flex;
        align-items: center;
        background: #f4f9fd;
        border-radius: 8px;
        // transition: all 0.1s ease-in;
        &:hover,
        &:focus {
          cursor: pointer;
          -webkit-box-shadow: 0px 0px 4px 1px #3287cd69;
          -moz-box-shadow: 0px 0px 4px 1px #3287cd69;
          box-shadow: 0px 0px 4px 1px #3287cd69;
        }
        .subscriptionCardImage {
          width: 65px;
          height: 50px;
          border-radius: 50%;
          margin-right: 15px;
          padding: 25px;
          // padding-right: 15px;
        }
        .subscriptionCardImage {
          width: 65px;
          height: 50px;
          border-radius: 50%;
          margin-right: 15px;
          padding: 25px;
          // padding-right: 15px;
        }
        & img {
          margin-right: 15px;
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
        .infoContainer {
          display: flex;
          flex-direction: column;
          // height: 100%;
          height: 50px;
          justify-content: space-between;
          .userName {
            text-align: left;
          }
          .userRelation {
            color: #636c83;
            text-align: left;
          }
        }
      }
    }
    .cardHref:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
img:hover {
  cursor: pointer;
}
.subscriptionList {
  position: absolute;
  width: 100%;
  max-width: 300px;
  // height: 100vh;
  max-height: 690px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 30px;
  overflow-y: auto;
  top: 80px;
  right: 0px;
  background: #ffffff;
  border-radius: 8px;
  background: white;
  padding-bottom: 10px;
  animation: subscriptionList 0.4s ease-in-out;
  .listTool {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    .title {
      align-self: center;
      justify-self: center;
      width: 100%;
      text-align: center;
      font-family: "SF UI Display Medium";
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }
      line-height: 20px;
    }
  }
  .subscriptionListBox {
    width: 100%;
    // height: 100vh;
    max-height: 550px;

    .subscriptionItem {
      font-family: "SF UI Display Medium", sans-serif;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      padding-top: 10px;
      background: #f4f9fd;
      border-radius: 8px;
      padding-left: 5px;
      padding-right: 5px;
      padding-bottom: 5px;
      -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
      box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      .subscriptionImg {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        margin-bottom: 10px;
      }
      .subscriptionPrice {
        margin-bottom: 10px;
      }
      .subscriptionTitle {
        margin-bottom: 15px;
        text-align: center;
      }
      .subscriptionDescription {
        text-align: center;
        margin-bottom: 10px;
      }
      .btn:not(:last-child) {
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
}

@keyframes subscriptionList {
  from {
    right: -100%;
    opacity: 0;
  }
  to {
    right: 0px;
    opacity: 1;
  }
}

.activationSuccessContainer {
  position: absolute;
  width: 100%;
  max-width: 300px;
  // height: 100vh;
  max-height: 690px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 30px;
  overflow-y: auto;
  top: 80px;
  right: 0px;
  background: #ffffff;
  border-radius: 8px;
  background: white;
  padding-bottom: 10px;
  animation: subscriptionList 0.4s ease-in-out;
  .activationBox {
    display: flex;
    width: 100%;
    height: calc(100% - 50px);
    // height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .btn {
      margin-top: auto;
    }
    img {
      margin-bottom: 20px;
    }
    .bookingText {
      font-family: "SF UI Display Medium", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
      color: #07141f;
    }
  }
}

.loyaltyLogo {
  // height: 90%;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  @media only screen and (max-device-width: 1024px) and (orientation: portrait) {
    margin-left: 10px;
    margin-right: 10px;
    // border-bottom: 1px solid #5295cc54;
  }
  &:hover,
  &:focus {
    transform: scale(1.03);
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 2px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 2px 0px rgba(34, 60, 80, 0.2);
  }
}
