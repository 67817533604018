.sider {
  width: 100%;
  max-width: 120px;
  height: 100%;
  background: #ffffff;
  border-radius: 8px;
  padding-top: 19px;
  display: flex;
  flex-direction: column;
  // -webkit-box-shadow: 0px 0px 2px 0px rgba(34, 60, 80, 0.2) inset;
  // -moz-box-shadow: 0px 0px 2px 0px rgba(34, 60, 80, 0.2) inset;
  // box-shadow: 0px 0px 2px 0px rgba(34, 60, 80, 0.2) inset;
  @media (max-width: 768px), screen and (orientation: portrait) {
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    padding-top: 0;
    border-radius: 0px;
    // padding-top: 5px;
  }
  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    border-radius: 0px;
    box-shadow: none;
  }
  .siderLogo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px), screen and (orientation: portrait) {
      display: none;
    }
    & img {
      @media screen and (max-width: 1024px) {
        max-width: 60px;
      }
    }
  }
  & > img {
    margin-bottom: 30px;
  }
  .siderOptions {
    justify-self: center;
    // max-height: 80%;
    max-height: 88%;
    overflow-y: auto;
    margin: auto 0;
    // padding: 2px;
    @media (max-width: 768px), screen and (orientation: portrait) {
      width: 100%;
      margin: 0 auto;
      height: 100%;
      max-height: 100%;
      display: flex;
      justify-content: space-between;
    }
    & a {
      @media (max-width: 768px), screen and (orientation: portrait) {
        width: 20%;
      }
    }
    @media only screen and (max-width: 1024px) and (orientation: landscape) {
      max-height: 90%;
    }
    .optionBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 15px;
      padding-top: 15px;
      min-height: 96px;
      @media (max-width: 768px), screen and (orientation: portrait) {
        padding-top: 0px;
        padding-bottom: 0px;
        height: 100%;
      }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        padding-bottom: 10px;
        padding-top: 10px;
      }
      &:hover,
      &:focus {
        background: #f2f2f2;
        cursor: pointer;
      }
      &.withIcon {
        position: relative;
        .unreadedCounter {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          right: 33px;
          font-family: "SF UI Display Medium", sans-serif;
          color: white;
          background: tomato;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          text-align: center;
          // padding-top: 5px;
          top: 5px;
          font-size: 13px;
          @media screen and (max-width: 1024px) {
            font-size: 10px;
          }
          z-index: 999;
        }
      }
      .analysisBox {
        width: 46px;
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #3288cd;
        border-radius: 50%;
        padding: 2px;
        &.notVisited {
          opacity: 0.6;
        }
      }
      .siderRoute {
        &:hover,
        &:focus {
          cursor: pointer;
        }
        &.notVisited {
          opacity: 0.6;
        }
      }
      .siderItemTitle {
        // max-width: 99%;
        // margin: 0 auto;
        text-align: center;
        font-family: "SF UI Display Medium", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        @media screen and (max-width: 1024px) {
          font-size: 12px;
        }
        line-height: 20px;
        text-align: center;
        color: #3288cd;
      }
    }
  }
}

.customIconBox {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3288cd;
}
