.titleContainer {
  position: relative;
  // width: 100%;
  width: auto;
  display: flex;
  align-items: center;
  height: 60px;
  margin-bottom: 8px;
  margin-right: 25px;
  @media screen and (max-width: 1024px) {
    margin-top: 5px;
  }
  .spinnerBox {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
  }
  .medConsultTitle {
    position: relative;
    // height: 65px;
    // margin-bottom: 20px;
    // margin-top: 20px;
    // text-align: center;
    justify-self: center;
    font-family: "SF UI Display Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    @media screen and (max-width: 1024px) {
      font-size: 18px;
    }
    // line-height: 20px;
    text-align: center;
    color: #212223;
    // margin-bottom: 20px;
    &.twoRows {
      display: block;
      .primary {
        font-family: "SF UI Display Medium", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        @media screen and (max-width: 1024px) {
          font-size: 16px;
        }
        line-height: 20px;
        color: #272d2d;
      }
      .secondary {
        font-family: "SF UI Display Medium", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        // @media screen and (max-width: 1024px) {
        //   font-size: 9px;
        // }
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #89a0b1;
      }
    }
  }
  .pageIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 57px;
    height: 57px;
    border-radius: 50%;
    border: 3px solid white;
    // background: white;
    background: #f4f9fd;
    margin-right: 10px;
    -webkit-box-shadow: 0px 0px 4px -1px #3288cd inset;
    -moz-box-shadow: 0px 0px 4px -1px #3288cd inset;
    box-shadow: 0px 0px 4px -1px #3288cd inset;
    .customIconBox {
      position: relative;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #3288cd;
      border-radius: 50%;
      .centerPosition {
        position: absolute;
        top: 2px;
        right: 1px;
      }
    }
  }
}
