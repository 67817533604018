@import "./style/fonts/style.scss";
// @import "./variables";

body {
  // overflow-x: hidden;
  // width: 1024px;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
    "SF UI Display Medium", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#portal {
  @media screen and (max-width: 550px) {
    display: none;
  }
}

* {
  box-sizing: border-box;
  font-family: "SF UI Display Medium", sans-serif;
  font-weight: normal;
  font-size: 14px;
  // @media screen and (max-width: 1024px) {
  //   font-size: 11px;
  // }
}

// img {
//   background-image: grey;
// }

#root {
  height: 100vh;
  // background: #e5e5e5;
  background: #f6f7fb;
  padding: 20px;
  @media (max-width: 768px), screen and (orientation: portrait) {
    padding: 0;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    padding: 0;
  }
}

.mainRowBox {
  height: 100%;
  display: flex;
  height: 100vh;
}
.mainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100%;
}

.container {
  display: flex;
  justify-content: center;
  // margin: 0 auto;
  // flex-direction: column;
  // justify-content: space-between;
  width: 100%;
  height: 87.5%;
}
.content {
  height: 100%;
  margin-left: 20px;
  width: 100%;
  max-width: 1260px;
  // max-width: 100%;
  // height: calc(100% - 300px);
  // background-color: #e5e5e5;
  background: #f6f7fb;
  position: relative;
  // overflow: hidden;
}

main {
  // display: flex;
  // background: #e5e5e5;
  background: #f6f7fb;
  width: 100%;
  height: 100%;
  // height: 100vh;
  // max-height: 930px;
}

.absoluteCenter {
  position: absolute;
  // top: 0;
  // left: 0;
  // bottom: 0;
  // right: 0;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transform: translate(-50%, -50%);
}

@keyframes animationLogo {
  0% {
    // opacity: 0.3;
    width: 60px;
    height: auto;
    // height: 60px;
  }
  // 50% {
  //   // opacity: 0.6;
  //   width: 80px;
  //   height: auto;
  //   // height: 70px;
  // }
  100% {
    // opacity: 0.8;
    width: 100px;
    height: auto;
    // height: 60px;
  }
}

// .content {
//   margin-left: 20px;
//   width: 100%;
//   max-width: 1310px;
//   // height: calc(100% - 300px);
//   // background-color: #e5e5e5;
//   background: #f6f7fb;
//   position: relative;
//   // overflow: hidden;
// }
// .container {
//   height: auto;
//   display: flex;
//   width: 100%;
//   max-width: 1400px;
//   margin: 0 auto;
//   // justify-content: ;
//   // overflow: hidden;
// }
.mainContentContainer {
  display: flex;
  justify-content: space-between;
  // height: 100%;
  width: 100%;
  // height: 100vh;
  // max-height: calc(100vh - 120px);
  // height: calc(100% - 80px);
  height: 100%;
}

main {
  position: relative;
  -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
}

.noBackground {
  background: none;
}

input,
button,
textarea {
  outline: none;
}
// span {
//   font-family: "SF UI Display Medium";
//   font-weight: 500;
// }
button {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.with-scrollbar {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
    background: unset;
  }
  &::-webkit-scrollbar-thumb {
    width: 10px;
    // height: 10%;
    border-radius: 8px;
    background: #3287cdbe;
    opacity: 0.7;
  }
  &::-webkit-scrollbar-track {
    border-radius: 8px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.15);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #3288cd;
  }
}

.wholeChatContainer {
  width: 100%;
  height: 100%;
  // display: flex;
  // display: grid;
  // grid-template-columns: 2fr 1fr;
  display: flex;
  // &.fullWidth {
  //   grid-template-columns: 2fr 0fr;
  // }
  &.fullscreen {
    grid-template-columns: 4fr 1fr;
    // &.fullWidth {
    //   grid-template-columns: 4fr 0fr;
    // }
    position: fixed;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999999999;
  }
}
