.fieldSimpleSelect {
  // width: 100%;
  position: relative;
  .spinnerBox {
    position: absolute;
    // top: 50%;
    top: 5px;
    right: 15px;
    // transform: translateY(-50%);
    width: 30px;
    height: 30px;
    z-index: 99;
    margin: 0;
  }
  .placeholder {
    position: absolute;
    top: -10px;
    left: 15px;
    color: #b9b9b9;
    opacity: 0.9;
    // transform: translateY(-50%);
    font-family: "SF UI Display", sans-serif;
    font-size: 13px;
    background: #f4f9fd;
    padding: 2px;
    z-index: 9;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .simpleInput {
    margin: 0px 4px;
    font-family: "SF UI Display Regular", sans-serif;
    height: 50px;
    border: 0;
    padding: 15px;
    outline: unset;
    width: 98.5%;
    height: 50px;
    background: #f6f7fb;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    &.isActive {
      -webkit-box-shadow: 0px -3px 3px 1px #3287cd69;
      -moz-box-shadow: 0px -3px 3px 1px #3287cd69;
      box-shadow: 0px -3px 3px 1px #3287cd69;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    @media screen and (max-width: 1024px) {
      font-size: 14px;
    }
    line-height: 17px;
    color: #272d2d;
    position: relative;
    &::placeholder {
      color: #b9b9b9;
    }
  }

  .value {
    left: 15px;
    font-size: 17px;
    width: 90%;
    display: flex;
    align-items: center;
    background: #f4f9fd;
    @media screen and (max-width: 1024px) {
      font-size: 14px;
    }
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    font-family: "SF UI Display Regular", sans-serif;
    // // top: 17px;
    // top: 50%;
    // left: 17px;
    height: 30px;
    padding-right: 25px;
    // background: white;
    // transform: translateY(-50%);
    // width: calc(100% - 22px);
  }

  & > img {
    position: absolute;
    top: 18px;
    right: 15px;
  }
  .scrollarea-content {
    z-index: 100;
  }
  .wrapperOptions {
    z-index: 10;
    // width: 98%;
    width: 100%;
    // margin: 0px 4px;
    position: absolute;
    // bottom: 1%;
    top: 80%;
    left: 50.173%;
    transform: translateY(-50%);
    transform: translateX(-50%);
    z-index: 11;

    .options {
      // margin: 0xp 3px;
      // z-index: 999;
      // -webkit-box-shadow: 0px -4px 12px -4px rgba(34, 60, 80, 0.2) inset;
      // -moz-box-shadow: 0px -4px 12px -4px rgba(34, 60, 80, 0.2) inset;
      // box-shadow: 0px -4px 12px -4px rgba(34, 60, 80, 0.2) inset;
      -webkit-box-shadow: 0px 3px 4px 1px #3287cd69;
      -moz-box-shadow: 0px 3px 4px 1px #3287cd69;
      box-shadow: 0px 3px 4px 1px #3287cd69;
      // box-shadow: ;
      // width: 100%;
      padding: 15px;
      // background: #f4f9fd;
      background: white;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      width: 98.5%;
      // margin: 0 auto;
      margin: 4px;
      max-height: 200px;
      overflow-y: auto;
      margin: 0 auto;
      margin-top: -1px;
      margin-bottom: 3px;
      &::-webkit-scrollbar {
        width: 4px;
        border-radius: 2px;
        background: unset;
      }
      &::-webkit-scrollbar-thumb {
        width: 4px;
        height: 10%;
        border-radius: 2px;
        background: #3288cd;
      }
      // max-width: 315px;
      // border: 1px solid #f6f7fb;
      .option {
        font-family: "SF UI Display Regular", sans-serif;
        text-align: center;
        width: 100%;
        cursor: pointer;
        padding: 15px 0;
        border-radius: 8px;
        color: #636c83;
        height: 50px;
        margin: 0px;
        &:hover,
        &:focus {
          cursor: pointer;
          // background: white;
          background: #f4f9fd;
          color: #3288cd;
        }
        // border-bottom: 1px solid #e7e7e7;
        &:last-child {
          border: none;
        }
      }
    }
  }
}
