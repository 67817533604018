.imageCropContainer {
  position: relative;
  width: 600px;
  width: auto;
  max-width: 600px;
  min-width: 300px;
  // height: 430px;
  height: auto;
  min-height: 430px;
  background: white;
  border-radius: 12px;
  padding: 20px;
  padding-top: 45px;
  display: flex;
  justify-content: center;
  -webkit-box-shadow: 0px 0px 8px 0px #3287cd71 inset;
  -moz-box-shadow: 0px 0px 8px 0px #3287cd71 inset;
  box-shadow: 0px 0px 8px 0px #3287cd71 inset;
  .dropContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    min-width: 400px;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3287cd7a;
    border: 2px solid #3287cd;
    border-style: dashed;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2) inset;
    -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2) inset;
    box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2) inset;
    &.activeDrag {
      background: #2062977a;
    }
    .dropTextBox {
      width: 90%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .btn {
        width: 200px;
        & span {
          width: 90%;
          font-size: 18px;
          @media screen and (max-width: 1024px) {
            font-size: 15px;
          }
        }
        margin-bottom: 10px;
      }
      .dropText {
        font-family: "SF UI Display Regular", sans-serif;
        font-size: 18px;
        @media screen and (max-width: 1024px) {
          font-size: 15px;
        }
        width: 70%;
        text-align: center;
      }
    }
  }
  .cropTitle {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-family: "SF UI Display Medium", sans-serif;
    font-size: 20px;
    @media screen and (max-width: 1024px) {
      font-size: 17px;
    }
    width: 100%;
    text-align: center;
  }
  .cropButtons {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 15px;
    display: flex;
    // margin-left: auto;
    width: 100%;
    // width: 200px;
    justify-content: flex-end;
    // margin-top: 10px;
    // margin-top: auto;
    margin-bottom: 0;
    .btn {
      max-width: 120px; // width: 40%;
      height: 40px;
      margin: 0;
      margin-left: 10px;
    }
    & .loadingBox {
      width: 50px;
      height: 40px;
      position: relative;
    }
  }
}
