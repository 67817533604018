.titleContainerMain {
  width: 100%;
  display: flex;
  align-items: center;
  .previousIcon {
    margin-right: 31px;
  }
  .imageBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #f4f9fd;
    border: 5px solid #ffffff;
    box-sizing: border-box;
    margin-right: 20px;
    & img {
      width: 50px;
      height: 50px;
    }
  }
  .pageTitle {
    font-family: "Roboto Bold", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    @media screen and (max-width: 1024px) {
      font-size: 25px;
    }
    line-height: 36px;
    color: #272d2d;
  }
}
