.fishkaCodeContainer {
  width: 400px;
  // height: 270px;
  border-radius: 10px;
  padding: 20px;
  padding-top: 20px;
  background: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .codeTitle {
    width: 100%;
    text-align: center;
    font-family: "SF UI Display Regular", sans-serif;
    font-size: 16px;
    margin-bottom: 10px;
  }
  // -webkit-box-shadow: 0px -2px 10px 4px rgba(34, 60, 80, 0.2) inset;
  // -moz-box-shadow: 0px -2px 10px 4px rgba(34, 60, 80, 0.2) inset;
  // box-shadow: 0px -2px 10px 4px rgba(34, 60, 80, 0.2) inset;
  .inputBox {
    position: relative;
    width: 100%;
    height: 60px;
    .inputError {
      position: absolute;
      left: 50%;
      top: 0px;
      transform: translateX(-50%);
      color: red;
      font-family: "Roboto Bold", sans-serif;
      font-size: 13px;
      width: 100%;
      text-align: center;
    }
    .fishkaCodeInput {
      width: 100%;
      height: 60px;
      padding: 10px;
      text-align: center;
      background: #f4f9fd;
      border-radius: 8px;
      margin: 0 auto;
      font-family: "Roboto Bold", sans-serif;
      font-size: 23px;
      -webkit-box-shadow: 0px 0px 8px -3px rgba(34, 60, 80, 0.2) inset;
      -moz-box-shadow: 0px 0px 8px -3px rgba(34, 60, 80, 0.2) inset;
      box-shadow: 0px 0px 8px -3px rgba(34, 60, 80, 0.2) inset;
      @media screen and (max-width: 1024px) {
        font-size: 19px;
      }
      border: none;
      &.withError {
        border: 1px solid red;
      }
    }
  }
  .repeatContainer {
    .repeat {
      font-family: "SF UI Display Regular", sans-serif;
      font-size: 16px;
    }
  }

  // .buttonContainer {
  //   position: absolute;
  //   bottom: 20px;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   max-width: 270px;
  // }
}
