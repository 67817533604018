.mobileRefContainer {
  display: none;
  .Dobrodoc {
    // margin: 0 auto;
    text-align: center;
    font-family: "SF UI Display Medium", sans-serif;
    font-size: 30px;
    @media screen and (max-width: 1024px) {
      font-size: 25px;
    }
    padding-top: 50px;
    padding-bottom: 30px;
  }
  .description {
    font-family: "SF UI Display Medium", sans-serif;
    font-size: 22px;
    @media screen and (max-width: 1024px) {
      font-size: 18px;
    }
    text-align: center;
    line-height: 22px;
    padding: 0;
    opacity: 0.8;
    margin: 0 0 20px;
    word-wrap: break-word;
    white-space: pre-line;
  }
  .refsContainer {
    width: 100%;
    // margin: 0 auto;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & a {
      margin-bottom: 25px;
      & img {
        // width: 150px;
      }
    }
  }
  .logoWrapper {
    margin: 0 auto;
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: #f4f9fd;
    -webkit-box-shadow: 0px 0px 16px 16px rgba(34, 60, 80, 0.28);
    -moz-box-shadow: 0px 0px 16px 16px rgba(34, 60, 80, 0.28);
    box-shadow: 0px 0px 16px 16px rgba(34, 60, 80, 0.28);
    & img {
      width: 130px;
      height: 130px;
    }
  }
  @media screen and (max-width: 550px) {
    position: fixed;
    overflow-y: auto;
    width: 100vw;
    height: 100vh;
    display: block;
    top: 0;
    left: 0;
    background: white;
    z-index: 99999;
    background-image: linear-gradient(rgb(50, 136, 205), rgb(95, 166, 179));
    color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99999999;
  }
}
