.videoChatContainer {
  height: 100%;
  position: relative;
  width: 100%;
  // padding-top: 25px;
  // padding-bottom: 20px;
  max-width: 100%;
  // max-height: 690px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  border-radius: 8px;
  &.fullscreen {
    // width: auto;
    width: 100%;
    max-width: 100vw;
    max-height: 100vh;
    background: #272d2d;
    & .doctorVideoChat {
      width: 100%;
      height: auto;
    }
  }
  .patientVideoChat {
    width: 200px;
    position: absolute;
    right: 5px;
    top: 10%;
    border-radius: 12px;
    z-index: 222;
    @media screen and (max-width: 1024px) and (orientation: portrait) {
      top: 5%;
    }
  }
  .doctorVideoChat {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    border-radius: 8px;
    // border: 1px solid white;
  }
  .toolPanel {
    width: 100%;
    display: flex;
    position: absolute;
    position: relative;
    bottom: 0;
    height: 80px;
    background: #3287cda4;
    .toolContainer {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      // position: absolute;
      // bottom: 10px;
      // left: 50%;
      // transform: translateX(-50%);
      width: 45%;
      max-width: 280px;
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      // background: #8b8c8f;
      padding: 5px;
      padding-left: 20px;
      padding-right: 20px;
      img {
        opacity: 0.7;
        &:hover,
        &:focus {
          opacity: 1;
          transform: scale(1.02);
        }
      }
      .showChatIcon {
        width: 40px;
        height: 40px;
        @media screen and (max-width: 1024px) and (orientation: portrait) {
          display: none;
        }
      }
      // background: lightgrey;
      // -webkit-box-shadow: 0px 0px 8px 2px rgba(34, 60, 80, 0.2) inset;
      // -moz-box-shadow: 0px 0px 8px 2px rgba(34, 60, 80, 0.2) inset;
      // box-shadow: 0px 0px 8px 2px rgba(34, 60, 80, 0.2) inset;
    }
    .tool2Container {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      // position: absolute;
      // bottom: 10px;
      // background: #8b8c8f;
      padding: 5px;
      // left: 50%;
      // transform: translateX(-50%);
      width: 45%;
      max-width: 110px;
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        opacity: 0.7;
        &:hover,
        &:focus {
          opacity: 1;
          transform: scale(1.02);
        }
      }
      .showChatIcon {
        width: 40px;
        height: 40px;
        @media screen and (max-width: 1024px) and (orientation: portrait) {
          display: none;
        }
      }
    }
  }
}
