.footer {
  // margin: 0 auto;
  // position: absolute;
  // left: 50%;
  // bottom: 20px;
  width: 100%;
  height: 100%;
  // max-width: 1400px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  // transform: translateX(-50%);
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  // margin-top: 20px;
  // height: 70px;
  // -webkit-box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2) inset;
  // -moz-box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2) inset;
  // box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2) inset;
  @media (max-width: 768px), screen and (orientation: portrait) {
    border-radius: 0px;
  }
  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    border-radius: 0px;
    box-shadow: none;
  }
  .footerImage {
    max-height: 50px;
    margin-right: 20px;
    @media screen and (max-width: 1024px) {
      max-height: 40px;
    }
  }
  .licence {
    font-family: "SF UI Display Medium", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    @media screen and (max-width: 1024px) {
      font-size: 8px;
    }
    line-height: 16px;
    width: 216px;
    height: 49px;
    color: #c4c4c4;
  }
  .infoRefs2 {
    width: 30%;
    display: flex;
    justify-content: space-between;
    font-family: "Roboto-Medium", sans-serif;
    font-size: 16px;
    @media screen and (max-width: 1024px) {
      font-size: 13px;
    }
    line-height: 20px;
    color: #c4c4c4;
    font-weight: 500;
    & div {
      text-decoration: none;
      margin-left: 5px;
      margin-right: 5px;
      & a {
        font-size: 16px;
        @media screen and (max-width: 1024px) {
          font-size: 13px;
        }
        text-decoration: none;
        color: inherit;
        color: #c4c4c4;
        transition: all 0.2 ease-in-out;
        position: relative;
        background: linear-gradient(to right, #ffffff, #ffffff),
          linear-gradient(to right, #3288cd, #3288cd, #3288cd);
        background-size: 100% 0.1em, 0 0.1em;
        background-position: 100% 100%, 0 100%;
        background-repeat: no-repeat;
        transition: background-size 400ms;
        &:hover,
        &:focus {
          // color: #3287cde5;
          color: #3288cd;
          background-size: 0 0.1em, 100% 0.1em;
          // transform: scale(1.07);
          // font-size: 16.5px;
        }
      }
    }
  }
  .infoRefs {
    margin-right: auto;
    margin-left: 20px;
    font-family: "Roboto-Medium", sans-serif;
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    @media screen and (max-width: 1024px) {
      font-size: 13px;
    }
    line-height: 20px;
    color: #c4c4c4;
    & div {
      // min-width: 120px;
      a {
        font-size: 16px;
        @media screen and (max-width: 1024px) {
          font-size: 13px;
        }
        text-decoration: none;
        color: #c4c4c4;
        // transition: all 0.2 ease-in-out;
        background: linear-gradient(to right, #ffffff, #ffffff),
          linear-gradient(to right, #3288cd, #3288cd, #3288cd);
        background-size: 100% 0.1em, 0 0.1em;
        background-position: 100% 100%, 0 100%;
        background-repeat: no-repeat;
        transition: background-size 400ms;
        &:hover,
        &:focus {
          color: #3287cde5;
          background-size: 0 0.1em, 100% 0.1em;
          // transform: scale(1.07);
          // font-size: 16.5px;
        }
      }
    }
    & div:not(:last-child) {
      margin-right: 30px;
    }
  }
  .infoContacts {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    @media screen and (max-width: 1024px) {
      font-size: 11px;
    }
    line-height: 30px;
    color: #c4c4c4;
    & div {
      margin-right: 23px;
      line-height: 20px;
    }
    & a {
      display: flex;
      justify-content: center;
      align-items: center;
      & img {
        &:hover,
        &:focus {
          transform: scale(1.07);
        }
      }
    }
    & a:not(:last-child) {
      margin-right: 15px;
    }
  }
}
