.backgroundCallContainer {
  position: fixed;
  right: 20px;
  top: 20px;
  width: 350px;
  height: 250px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 4px 1px #3287cd69;
  -moz-box-shadow: 0px 0px 4px 1px #3287cd69;
  box-shadow: 0px 0px 4px 1px #3287cd69;
  z-index: 99999;
  background: white;
  overflow: hidden;
  // &:hover {
  //   cursor: move;
  // }
}
.draggable {
  cursor: move; /* fallback: no `url()` support or images disabled */
  // cursor: url(images/grab.cur); /* fallback: Chrome 1-21, Firefox 1.5-26, Safari 4+, IE, Edge 12-14, Android 2.1-4.4.4 */
  cursor: move; /* W3C standards syntax, all modern browser */
}

.draggable:active {
  // cursor: url(images/grabbing.cur);
  cursor: grabbing;
}

.flexible-modal {
  position: absolute;
  z-index: 1;
  border: 1px solid #ccc;
  background: hsl(0, 0%, 100%);
  z-index: 12;
}
.flexible-modal-mask {
  position: fixed;
  // height: 100%;
  // background: rgba(55, 55, 55, 0.6);
  top: 20px;
  right: 20px;
  width: 0px;
  height: 0px;
}
.flexible-modal-resizer {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: se-resize;
  margin: 5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}
.flexible-modal-drag-area {
  // background: rgba(22, 22, 333, 0.2);
  background: transparent !important;
  height: calc(100% - 50px) !important;
  position: absolute;
  right: 0;
  top: 0;
  cursor: move;
}
.my-modal-custom-class {
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 4px 1px #3287cd69;
  -moz-box-shadow: 0px 0px 4px 1px #3287cd69;
  box-shadow: 0px 0px 4px 1px #3287cd69;
  .toolPanel {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 50px;
    border-top: 1px solid #3287cd;
    background: #3287cd;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 15px;
    }
  }
  .videoContainer {
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    .doctorBackgroundVideo {
      // position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      margin: 0 auto;
    }
    .patientBackgroundVideo {
      position: absolute;
      right: 5px;
      top: 5px;
      width: 20%;
      height: 20%;
      border-radius: 15px;
    }
  }
}
