main {
  .appWrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 1400px;
    height: 100%;
    // overflow-y: hidden;
    display: grid;
    grid-template-columns: 0.9fr 20px 7fr 20px 2.26fr;
    grid-auto-rows: 0.6fr 20px 7fr 20px 0.7fr;
    grid-template-areas:
      "nav . hd hd hd"
      "nav . . . ."
      "nav . cont . sd"
      ". . . . ."
      "ft ft ft ft ft";
    @media screen and (max-width: 1024px) and (orientation: landscape) {
      grid-template-columns: 0.9fr 8fr;
      grid-template-rows: 0.7fr 7fr 10px 0.8fr;
      grid-auto-rows: minmax(0px, auto);
      grid-template-areas:
        "nav hd hd hd hd"
        "nav cont cont cont cont"
        ". . . . ."
        "ft ft ft ft ft";
    }
    @media (max-width: 768px), screen and (orientation: portrait) {
      grid-template-columns: 0.9fr 0.8fr 7fr 20px 2.26fr;
      grid-auto-rows: 0.8fr 90px 7fr 20px 0.7fr;
      grid-template-areas:
        "hd hd hd hd hd"
        "nav nav nav nav nav"
        "cont cont cont cont cont"
        ". . . . ."
        "ft ft ft ft ft";
    }
    .siderArea {
      grid-area: nav;
    }
    .headerArea {
      grid-area: hd;
    }
    .sidebarArea {
      overflow-y: hidden;
      grid-area: sd;
      @media (max-width: 768px), screen and (orientation: portrait) {
        display: none;
      }
    }
    .contentArea {
      overflow-y: hidden;
      grid-area: cont;
      border-radius: 8px;
      @media only screen and (max-device-width: 1024px) and (orientation: landscape) {
        border-radius: 0px;
        border: none;
        box-shadow: none;
      }
    }
    .footerArea {
      overflow-y: hidden;
      grid-area: ft;
    }
  }
}
.homeContainer {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  overflow: hidden;
  .mainContainer {
    display: flex;
    justify-content: space-between;
  }
}

@keyframes siderAnim {
  from {
    transform: translateX(-700px);
    opacity: 0.7;
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes headerAnim {
  from {
    transform: translateY(-700px);
    opacity: 0.7;
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes footerAnim {
  from {
    transform: translateY(700px);
    opacity: 0.7;
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes sidebarAnim {
  from {
    transform: translateX(700px);
    opacity: 0.7;
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes contentAnim {
  from {
    transform: scale(1.3);
    opacity: 0.7;
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
