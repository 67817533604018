.phoneInput {
  border: 1px solid transparent;
  outline: unset;
  width: 100%;
  height: 100%;
  width: 98%;
  margin: 0 3px;
  padding: 15px;
  background: #f6f7fb;
  border-radius: 8px;
  font-family: "SF UI Display Medium", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 29px;
  text-align: center;
  color: #262e42;
  color: #272d2d;
}
.searchInput {
  border: none;
}
.phoneDropDownButton {
  .selected-flag {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
    &:hover {
      background: transparent;
      // border: 1px solid black;
    }
    .flag {
      transform: scale(1.2);
    }
  }
  &.open.flag-dropdown {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    .selected-flag {
      background: #f6f7fb;
    }
  }
}
.dropDown {
  .search-emoji {
    display: none;
  }
  .country {
    border: none;
    font-size: 20px;
    font-family: "SF UI Display Medium", sans-serif;
    &:hover {
      background-color: #f6f7fb;
    }
  }
}
