.formField {
  height: 100%;
}
.formFieldSimpleInput {
  width: 100%;
  height: 100%;
  position: relative;
  font-family: "SF UI Display Medium", sans-serif;
  .inputLabel {
    font-family: "SF UI Display Regular", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    @media screen and (max-width: 1024px) {
      font-size: 7px;
    }
    line-height: 12px;
    position: absolute;
    font-size: 12px;
    @media screen and (max-width: 1024px) {
      font-size: 9px;
    }
    z-index: 10;
    top: -7px;
    left: 15px;
    color: #b9b9b9;
    background: #f4f9fd;
    // background: white;
    padding: 2px;
    padding-left: 3px;
    padding-right: 3px;
    display: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .simpleInput {
    // margin: 3px;
    border: 0;
    background: #ffffff;
    border-radius: 4px;
    word-wrap: break-word;
    outline: unset;
    width: 98%;
    // width: 97%;
    margin: 0px 3px;
    height: 100%;
    padding: 12px;
    font-family: "SF UI Display Regular", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    @media screen and (max-width: 1024px) {
      font-size: 14px;
    }
    line-height: 20px;
    text-align: left;
    color: #272d2d;
    &::placeholder {
      color: #b9b9b9;
    }
    &:focus::placeholder {
      color: #f6f7fb;
    }
    &::-webkit-calendar-picker-indicator {
      background: none;
    }
    &:focus {
      // border: 1px solid lightgrey;
      -webkit-box-shadow: 0px 0px 4px 1px #3287cd69;
      -moz-box-shadow: 0px 0px 4px 1px #3287cd69;
      box-shadow: 0px 0px 4px 1px #3287cd69;
    }
  }
  .errorRequire {
    text-align: left;
    color: #db5757;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    @media screen and (max-width: 1024px) {
      font-size: 11px;
    }
    line-height: 20px;
    position: absolute;
    // background-color: #f6f7fb;
    top: 30%;
    left: 12px;
    z-index: 5;
    width: calc(100% - 22px);
    // width: 100%;
    display: none;
  }
  .errorRequire.show {
    display: block;
  }
  .errorRequire.show + .errorMessage.show {
    display: none;
  }
  .errorMessage {
    display: none;
    opacity: 0.9;
    color: #db5757;
    position: absolute;
    font-weight: 500;
    font-size: 12px;
    @media screen and (max-width: 1024px) {
      font-size: 9px;
    }
    line-height: 13px;
    background-color: #f6f7fb;
    top: 2px;
    z-index: 10;
    left: 12px;
    width: calc(100% - 22px);
  }
  .simpleInput:focus + .inputLabel {
    display: inherit;
  }
  .simpleInput:valid + .inputLabel {
    display: inherit;
    // top: -5px;
  }
  .show ~ .inputLabel {
    display: none !important;
  }
  .errorMessage.show ~ .simpleInput,
  .errorRequire.show ~ .simpleInput {
    opacity: 0.9;
    // border: 1px solid red;
    -webkit-box-shadow: 0px 0px 4px 1px rgba(255, 0, 0, 0.514);
    -moz-box-shadow: 0px 0px 4px 1px rgba(255, 0, 0, 0.514);
    box-shadow: 0px 0px 4px 1px rgba(255, 0, 0, 0.514);
  }
  .errorMessage.show {
    display: block;
    top: -7px;
    padding-left: 2px;
    padding-right: 2px;
    font-size: 14px;
    color: red;
    @media screen and (max-width: 1024px) {
      font-size: 9px;
    }
    width: auto;
  }
}

.authFieldSimpleInput {
  width: 100%;
  height: 100%;
  position: relative;
  font-family: "SF UI Display Medium", sans-serif;
  .inputLabel {
    font-family: "SF UI Display Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    @media screen and (max-width: 1024px) {
      font-size: 7px;
    }
    line-height: 12px;
    position: absolute;
    font-size: 10px;
    @media screen and (max-width: 1024px) {
      font-size: 7px;
    }
    top: 5px;
    left: 15px;
    color: #b9b9b9;
    display: none;
  }
  .simpleInput {
    // overflow: hidden;
    border: 1px solid transparent;
    // padding: 15px;
    outline: unset;
    // width: 100%;
    // width: 340px;
    width: 100%;
    height: 100%;
    width: 98%;
    margin: 0px 3px;
    // height: 70px;
    padding: 15px;
    background: #f6f7fb;
    border-radius: 8px;
    font-family: "SF UI Display Medium", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    @media screen and (max-width: 1024px) {
      font-size: 19px;
    }
    line-height: 29px;
    text-align: center;
    // padding-left: 60px;
    color: #262e42;
    color: #272d2d;
    &::placeholder {
      color: #b9b9b9;
    }
    &:focus::placeholder {
      color: #f6f7fb;
    }
    &::-webkit-calendar-picker-indicator {
      background: none;
    }
    &:focus {
      // border: 1px solid #3288cd;
      -webkit-box-shadow: 0px 0px 4px 1px #3287cd69;
      -moz-box-shadow: 0px 0px 4px 1px #3287cd69;
      box-shadow: 0px 0px 4px 1px #3287cd69;
    }
  }
  .errorRequire {
    text-align: center;
    color: #db5757;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    @media screen and (max-width: 1024px) {
      font-size: 19px;
    }
    line-height: 29px;
    position: absolute;
    // background-color: #f6f7fb;
    top: 30%;
    left: 15px;
    z-index: 5;
    width: calc(100% - 22px);
    // width: 100%;
    display: none;
  }
  .errorRequire.show {
    display: block;
  }
  .errorRequire.show + .errorMessage.show {
    display: none;
  }
  .errorMessage {
    display: none;
    opacity: 0.9;
    color: #db5757;
    position: absolute;
    font-weight: 500;
    font-size: 14px;
    @media screen and (max-width: 1024px) {
      font-size: 10px;
    }
    line-height: 17px;
    background-color: #f6f7fb;
    top: 2px;
    z-index: 10;
    left: 15px;
    width: calc(100% - 22px);
  }
  .simpleInput:focus + .inputLabel {
    display: inherit;
  }
  .simpleInput:valid + .inputLabel {
    display: inherit;
  }
  .show ~ .inputLabel {
    display: none !important;
  }
  .errorMessage.show ~ .simpleInput,
  .errorRequire.show ~ .simpleInput {
    opacity: 0.9;
    // border: 1px solid red;
    -webkit-box-shadow: 0px 0px 4px 1px rgba(255, 0, 0, 0.514);
    -moz-box-shadow: 0px 0px 4px 1px rgba(255, 0, 0, 0.514);
    box-shadow: 0px 0px 4px 1px rgba(255, 0, 0, 0.514);
  }
  .errorRequire.show ~ .simpleInput::placeholder {
    opacity: 0;
  }
  .errorMessage.show {
    display: block;
  }
}
