.bufferSection {
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  height: 100%;
  display: grid;
  grid-template-columns: 0.9fr 20px 7fr 20px 2.26fr;
  grid-auto-rows: 0.6fr 20px 7fr 20px 0.7fr;
  grid-template-areas:
    "nav . hd hd hd"
    "nav . . . ."
    "nav . cont . sd"
    ". . . . ."
    "ft ft ft ft ft";
  @media (max-width: 768px), screen and (orientation: portrait) {
    grid-template-columns: 0.9fr 0.8fr 7fr 20px 2.26fr;
    grid-auto-rows: 0.6fr 90px 7fr 20px 0.7fr;
    grid-template-areas:
      "hd hd hd hd hd"
      "nav nav nav nav nav"
      "cont cont cont cont cont"
      ". . . . ."
      "ft ft ft ft ft";
  }
  // animation: placeholderAnimation 0.8s ease-in infinite;
  .placeholderNavigation {
    grid-area: nav;
    background: rgba(211, 211, 211, 0.158);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .placeholderNavItem {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: rgba(211, 211, 211, 0.308);
      &:not(:last-child) {
        margin-bottom: 25px;
      }
      &.logo {
        width: 70px;
        height: 70px;
        position: absolute;
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .placeholderHeader {
    @extend .placeholderNavigation;
    grid-area: hd;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    .placeholderHeaderItem {
      background: rgba(211, 211, 211, 0.308);
      &.search {
        height: 75%;
        width: 67%;
        border-radius: 8px;
      }
      &.fishka {
        height: 50px;
        width: 50px;
        border-radius: 50%;
      }
      &.toolbar {
        height: 75%;
        width: 20%;
        border-radius: 8px;
      }
    }
  }
  .placeholderFooter {
    @extend .placeholderNavigation;
    grid-area: ft;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    .footerBox {
      width: 200px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .placeholderFooterItem {
      background: rgba(211, 211, 211, 0.308);
      &.circle {
        width: 55px;
        height: 55px;
        border-radius: 50%;
      }
      &.text {
        height: 30px;
        width: 100px;
        border-radius: 8px;
      }
    }
  }
  .placeholderContent {
    @extend .placeholderNavigation;
    grid-area: cont;
    display: grid;
    padding: 5px;
    display: block;
    .contentBox {
      width: 100%;
      height: 80%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
      column-gap: 20px;
      row-gap: 20px;
      .placeholderContentItem {
        background: rgba(211, 211, 211, 0.308);
        border-radius: 8px;
      }
    }
    .contentTitleBox {
      height: 59px;
      width: 100%;
      margin-bottom: 25px;
      // margin-left: 20px;
      margin-top: 10px;
      .title {
        background: rgba(211, 211, 211, 0.308);
        height: 100%;
        width: 190px;
        border-radius: 8px;
      }
    }
  }
  .placeholderSidebar {
    @extend .placeholderNavigation;
    grid-area: sd;
    padding: 15px;
    .placeholderSidebarItem {
      background: rgba(211, 211, 211, 0.308);
      width: 100%;
      height: 27%;
      border-radius: 10px;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
  & .bufferImage {
    width: 50px;
    height: 50px;
    animation: animationLogo 1.1s ease-in-out infinite;
  }
}
// @keyframes placeholderAnimation {
//   from {
//     opacity: 0.8;
//   }
//   to {
//     opacity: 0.9;
//   }
// }

.loadingPlaceholderBox {
  position: fixed;
  width: calc(100vw - 40px);
  height: calc(100vh - 40px);
  background: #f6f7fb;
  // background: #3287cd33;
  border-radius: 10px;
  .logoBox {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 215px;
    height: 215px;
    border-radius: 50%;
    overflow: hidden;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      content: "";
      position: absolute;
      top: 0px;
      left: -150px;
      width: 100px;
      height: 100%;
      // background: rgba(255, 255, 255, 0.3);
      background: #f6f7fb63;
      transform: skewX(-35deg);
      animation: slider 1s infinite ease-in;
      z-index: 999999;
    }
  }
  .logo {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@keyframes slider {
  from {
    left: -150px;
  }
  to {
    left: 300px;
  }
}
