.errorPageContainer {
  width: 100vw;
  height: 100vh;
  // padding-top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  .errorWrapper {
    padding: 20px;
    // width: 40%;
    // height: 30%;
    max-width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: white;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2) inset;
    -moz-box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2) inset;
    box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2) inset;
    .errorLogo {
      width: 200px;
      height: 200px;
    }
    .error-title {
      text-align: center;
      font-family: "SF UI Display Medium", sans-serif;
      font-size: 30px;
      @media screen and (max-width: 1024px) {
        font-size: 25px;
      }
    }
  }
}
