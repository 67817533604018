.registrationPageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  #inputbirthDate {
    padding-left: 25px;
  }
  .formField {
    .placeholder {
      left: 25px;
    }
  }
}

.registrationBox {
  overflow-y: hidden;
  position: relative;
  width: 80vw;
  height: 80vh;
  max-width: 600px;
  border-radius: 15px;
  background: white;
  -webkit-box-shadow: 0px 0px 5px 1px #3287cd69;
  -moz-box-shadow: 0px 0px 5px 1px #3287cd69;
  box-shadow: 0px 0px 5px 1px #3287cd69;
}

.registerTitle {
  width: 100%;
  text-align: center;
  font-family: "SF UI Display Medium", sans-serif;
  letter-spacing: 1px;
  font-weight: 800;
  font-size: 23px;
  margin: 20px 0px;
  color: #3287cd;
}

.bannerBox {
  // position: relative;
  // overflow: hidden;
  width: 600px;
  height: 600px;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  .animationBox {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    display: flex;
    width: auto;
    &.animRight {
      animation: slideLeft 1s ease-in forwards;
    }
    &.animLeft {
      animation: slideLeft 1s ease-in;
    }
  }
}

.bannerItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // width: 80%;
  // width: 100%;
  width: 600px;
  margin: 0px 30px;
  max-width: 550px;
  min-width: 400px;
  height: 100%;
  .bannerImage {
    margin: 0 auto;
  }
  .bannerText {
    // margin: 20px 0px;
    padding-bottom: 30px;
    margin-top: 30px;
    margin-bottom: 0;
    width: 100%;
    text-align: center;
    font-family: "SF UI Display Medium", sans-serif;
    font-size: 23px;
    letter-spacing: 1px;
    font-weight: 800;
  }
}

.nextButtonBox {
  position: absolute;
  bottom: 10px;
  width: 90%;
  margin: 0 auto;
  height: 50px;
  left: 50%;
  transform: translateX(-50%);
}

.registerFormContainer {
  animation: formAnim 0.4s ease-in;
  width: 600px;
  .modeInfoContainer {
    & .columnContainer {
      height: 320px;
      max-height: 999px;
    }
  }
  .formFieldSimpleInput {
    height: 50px;
    .simpleInput {
      height: 50px;
    }
  }
  .simpleInput {
    height: 50px;
  }
}
#root
  > div.registrationPageContainer
  > div
  > div.registerFormContainer
  > form
  > div
  > div {
  margin-bottom: 10px;
}
#root
  > div.registrationPageContainer
  > div
  > div.registerFormContainer
  > form
  > div {
  padding-top: 0;
  padding-bottom: 0;
}
#root
  > div.registrationPageContainer
  > div
  > div.registerFormContainer
  > form
  > div
  > div:nth-child(1)
  > div {
  height: 50px;
}

#root
  > div.registrationPageContainer
  > div
  > div.registerFormContainer
  > form
  > div
  > div
  > div
  > div
  > label.errorMessage.show {
  top: 3px;
}

.registrationPageContainer {
  #inputemail,
  #input,
  #idgender > div > div:nth-child(1) > input,
  #inputfirstName,
  #inputpatronymic,
  #inputlastName,
  #inputphone {
    height: 50px;
  }
}

@keyframes formAnim {
  from {
    transform: translateX(600px);
  }
  to {
    transform: translateX(0px);
  }
}
