.mainFormContainer {
  overflow-y: auto;
  height: 100%;
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 2px;
    background: unset;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    height: 10%;
    border-radius: 2px;
    background: #3288cd;
  }
  .newAddressFormContainer {
    width: 100%;
    max-width: 420px;
    height: 100%;
    max-height: 510px;
    margin-left: auto;
    .addressFormContainer {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 10px;
      form {
        .inlineContainer {
          & div {
            margin: 0 auto;
            max-width: 180px;
          }
        }
      }
    }
  }
  .btn {
    min-height: 50px;
    @media screen and (max-width: 1024px) {
      min-height: 40px;
      height: 40px;
    }
  }
}

form {
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 2px;
    background: unset;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    height: 10%;
    border-radius: 2px;
    background: #3288cd;
  }
  .newAddressFormContainer {
    width: 100%;
    max-width: 420px;
    height: 100%;
    max-height: 510px;
    margin-left: auto;
    .addressFormContainer {
      padding-left: 20px;
      padding-right: 20px;
      form {
        .inlineContainer {
          & div {
            margin: 0 auto;
            max-width: 180px;
          }
        }
      }
    }
  }
  .btn {
    min-height: 50px;
  }
}
// .mainFormContainer{

// }
.modeInfoContainer {
  width: 100%;
  height: 100%;
  max-height: 330px;
  min-height: 330px;

  // padding: 40px;
  display: flex;
  // overflow-y: auto;
  justify-content: space-between;
  .columnContainer {
    height: 100%;
    max-height: 330px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 48%;
    margin-top: 7px;
    max-width: 420px;
    @media screen and (max-width: 1024px) {
      max-height: 300px;
    }
    .columnFieldBox {
      height: 50px;
      width: 100%;
    }
    .addressBox {
      height: 50px;
      width: 100%;
      // display: flex;
      padding-right: 50px;
      position: relative;
      &.noPadding {
        padding-right: 0;
      }
      // justify-content: space-between;
      .addressButtonContainer {
        position: absolute;
        top: 0;
        right: 0;
        // height: 45px;
        width: 50px;
        .btn {
          // height: 45px;
          // height: 30px;
          border-radius: 4px;
          opacity: 0.5;
          &:hover,
          &:focus {
            opacity: 0.8;
          }
          // margin-left: 3px;
          span {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-weight: 300;
            font-size: 30px;
            @media screen and (max-width: 1024px) {
              font-size: 25px;
            }
          }
        }
      }
      & input {
        background: #f4f9fd;
        border-radius: 8px;
      }
    }
    & input {
      background: #f4f9fd;
      border-radius: 8px;
    }
  }
}

.fishkaModeContainer {
  padding: 25px;
  width: 600px;
  margin: 0 auto;
  // position: relative;
  // height: 100%;
  .registerButton {
    position: absolute;
    width: calc(100% - 40px);
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
  }
  .authCheckbox {
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
    margin-bottom: 50px;
  }
  .fishkaInputBox {
    height: 60px;
    width: 500px;
    margin: 0 auto;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    .fieldSimpleSelect {
      // @media screen and (max-width: 1024px) {
      //   height: 40px;
      // }
      .circle {
        top: 50%;
        transform: translateY(-50%);
      }
      .value {
        left: 25px;
        font-size: 17px;
        width: 90%;
        display: flex;
        align-items: center;
        background: #f4f9fd;
        @media screen and (max-width: 1024px) {
          font-size: 14px;
        }
        top: 50%;
        transform: translateY(-50%);
      }
      .simpleInput {
        padding-left: 22px;
        height: 60px;
        padding-right: 10px;
        background: #f4f9fd;
        // border-radius: 15px;
        // height: 60px;
        // -webkit-box-shadow: 0px 0px 7px -1px rgba(34, 60, 80, 0.2) inset;
        // -moz-box-shadow: 0px 0px 7px -1px rgba(34, 60, 80, 0.2) inset;
        // box-shadow: 0px 0px 7px -1px rgba(34, 60, 80, 0.2) inset;
        // padding-left: 25px;
      }
      .shevron {
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .formField {
      .errorMessage {
        left: 25px;
        top: 6px;
        width: auto;
        font-size: 13px;
        @media screen and (max-width: 1024px) {
          font-size: 10px;
        }
      }
      .form-control {
        // border-radius: 15px;
        height: 60px;
        padding-left: 25px;
        font-size: 17px;
        // @media screen and (max-width: 1024px) {
        //   font-size: 14px;
        // }
        // -webkit-box-shadow: 0px 0px 9px -1px rgba(34, 60, 80, 0.2) inset;
        // -moz-box-shadow: 0px 0px 9px -1px rgba(34, 60, 80, 0.2) inset;
        // box-shadow: 0px 0px 9px -1px rgba(34, 60, 80, 0.2) inset;
        &:focus {
          // border: 1px solid lightgrey;
          -webkit-box-shadow: 0px 0px 4px 1px #3287cd69;
          -moz-box-shadow: 0px 0px 4px 1px #3287cd69;
          box-shadow: 0px 0px 4px 1px #3287cd69;
        }
        // .errorMessage {
        //   left: 25px;
        // }
      }
      .formFieldSimpleInput {
        .simpleInput {
          border-radius: 8px;
          padding-left: 25px;
          height: 60px;
          background: #f4f9fd;
          font-size: 17px;
          @media screen and (max-width: 1024px) {
            font-size: 15px;
          }
          // transition: all 0.1s ease-in;
        }
        .inputLabel {
          left: 25px;
          font-size: 13px;
          border-radius: 5px;
          // background: white;
          background: #f4f9fd;
          // -webkit-box-shadow: 0px 0px 4px 1px #3287cd69;
          // -moz-box-shadow: 0px 0px 4px 1px #3287cd69;
          // box-shadow: 0px 0px 4px 1px #3287cd69;
          @media screen and (max-width: 1024px) {
            font-size: 10px;
          }
        }
        .errorMessage {
          font-family: "Roboto Bold", sans-serif;
          left: 25px;
          font-size: 13px;
          background: transparent;
          @media screen and (max-width: 1024px) {
            font-size: 10px;
          }
          color: red;
        }
      }
    }
  }
}
#input {
  font-size: 16px;
}
.form-control {
  &:focus {
    -webkit-box-shadow: 0px 0px 4px 1px #3287cd69;
    -moz-box-shadow: 0px 0px 4px 1px #3287cd69;
    box-shadow: 0px 0px 4px 1px #3287cd69;
  }
}
