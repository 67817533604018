.historyContainer {
  width: 100%;
  height: 100%;
  // max-height: 441px;
  overflow-y: auto;
  @media screen and (max-width: 1024px) {
    height: 99%;
  }
  .emptyCase {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "SF UI Display Medium", sans-serif;
    font-style: normal;
    // font-weight: 500;
    font-size: 30px;
    @media screen and (max-width: 1024px) {
      font-size: 25px;
    }
    line-height: 30px;
    text-align: center;
    opacity: 0.5;
  }
  .absoluteCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    transform: translate(-50%, -50%);
  }
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
    background: unset;
  }
  &::-webkit-scrollbar-thumb {
    width: 10px;
    // height: 10%;
    border-radius: 8px;
    background: #3287cdbe;
    opacity: 0.7;
  }
  &::-webkit-scrollbar-track {
    border-radius: 8px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.15);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #3288cd;
  }
  .historyItem:not(:last-child) {
    margin-bottom: 18px;
    @media screen and (max-width: 1024px) {
      margin-bottom: 10px;
    }
  }
  .historyItem {
    margin-top: 2px;
    margin-left: 2px;
    position: relative;
    // transition: all 0.2s ease-in-out;
    width: 99%;

    &:hover,
    &:focus {
      cursor: pointer;
      -webkit-box-shadow: 0px 0px 4px 1px #3287cd69;
      -moz-box-shadow: 0px 0px 4px 1px #3287cd69;
      box-shadow: 0px 0px 4px 1px #3287cd69;
    }
    // margin-bottom: 20px;
    font-family: "SF UI Display Medium", sans-serif;
    // border: 1px solid #07141f;
    height: 85px;
    display: flex;
    background: #f4f9fd;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-radius: 8px;
    padding-right: 35px;
    @media screen and (max-width: 1024px) and (orientation: portrait) {
      margin-bottom: 10px;
      padding: 7px;
      padding-right: 30px;
      justify-content: space-evenly;
    }
    @media screen and (max-width: 1024px) and (orientation: landscape) {
      margin-bottom: 10px;
      padding: 7px;
      padding-right: 30px;
      padding-left: 20px;
      justify-content: space-between;
    }
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25%;
    }
    & > div:last-child {
      width: 15%;
    }
    & > div:first-child {
      width: 27%;
    }
    & > div .showMoreImg {
      position: absolute;
      right: 12px;
      top: 48%;
      margin-left: auto;
      // transform: translateY(-60%);
      transform: rotate(-90deg);
      &.rotate {
        transform: rotate(90deg);
      }
    }
    & .showMoreImg {
      position: absolute;
      right: 12px;
      top: 48%;
      margin-left: auto;
      // transform: translateY(-60%);
      transform: rotate(-90deg);
      &.rotate {
        transform: rotate(90deg);
      }
    }
    .doctorPhoto {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 15px;
    }
    .consultStatusContainer {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .statustitle {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        @media screen and (max-width: 1024px) {
          font-size: 11px;
        }
        line-height: 20px;
        color: #89a0b1;
      }
      .consultationStatus {
        padding: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        border-radius: 8px;
        text-align: center;
        font-family: "SF UI Display Medium", sans-serif;
        font-weight: 500;
        font-size: 14px;
        @media screen and (max-width: 1024px) {
          font-size: 13px;
        }
        font-style: normal;
        color: #f4f9fd;
        width: 95px;
        -webkit-box-shadow: 0px 0px 3px 1px rgba(34, 60, 80, 0.1) inset;
        -moz-box-shadow: 0px 0px 3px 1px rgba(34, 60, 80, 0.1) inset;
        box-shadow: 0px 0px 3px 1px rgba(34, 60, 80, 0.1) inset;
        &.new {
          background: #fcdf22;
          // color: #9da4aa;
          color: black;
        }
        &.closed {
          background: #7dd952;
          opacity: 0.8;
        }
        &.in_process {
          background: #52d9d1;
        }
        &.cancelled {
          background: tomato;
        }
        &.filled {
          background: #3287cd;
        }
      }
    }
    .consultInfoContainer {
      // height: 100%;
      &.type {
        min-width: 100px;
        // @media screen and (max-width: 1024px) {
        //   max-width: 83px;
        // }
      }
      min-width: 153px;
      @media screen and (max-width: 1024px) {
        min-width: 113px;
      }
      // @media screen and (max-width: 980px) {
      //   min-width: 50px;
      // }
      // @media screen and (max-width: 1100px) {
      //   min-width: 100px;
      // }
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .doctorName {
        // font-family: "SF UI Display Medium", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        @media screen and (max-width: 1024px) {
          font-size: 14px;
        }
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #07141f;
      }
      .specialtyTitle {
        // font-family: "SF UI Display Medium", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        @media screen and (max-width: 1024px) {
          font-size: 11px;
        }
        line-height: 20px;
        color: #8aa0b1;
      }
      .dateTitle {
        // font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        @media screen and (max-width: 1024px) {
          font-size: 11px;
        }
        line-height: 20px;
        color: #89a0b1;
      }
      .consultationDate {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        @media screen and (max-width: 1024px) {
          font-size: 12px;
        }
        line-height: 20px;
        color: #272d2d;
      }
      .consultationType {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        @media screen and (max-width: 1024px) {
          font-size: 11px;
        }
        line-height: 20px;
        color: #89a0b1;
      }
      .consultationMethod {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        @media screen and (max-width: 1024px) {
          font-size: 13px;
        }
        line-height: 20px;
        color: #272d2d;
      }
    }
  }
}
.consultationImage {
  margin-top: 5px;
}

.offerBox {
  position: absolute;
  width: 100%;
  height: 230px;
  .offer {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    font-family: "SF UI Display Regular", sans-serif;
    font-size: 23px;
    @media screen and (max-width: 1024px) {
      font-size: 19px;
    }
  }
  .btnContainer {
    width: 90%;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 110px;
  }
}

.fondyPayment {
  height: 88%;
  width: 100%;
  height: 90%;
  left: 0;
  top: 0;
  position: absolute;
  animation: stepForwardAnimation 0.3s ease-in-out;
  border-radius: 8px;
}

.consultInfoBox {
  position: relative;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 200px;
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // grid-auto-rows: minmax(100px);
  // column-gap: 10px;
  // row-gap: 10px;
  // grid-template-areas:
  //   "complaints drugs"
  //   "conclusion drugs"
  //   "analysis drugs"
  //   "diagnostic diagnostic";
  padding-bottom: 15px;
  padding-top: 10px;
  // &.noContent {
  //   position: relative;
  //   display: flex;
  //   justify-content: center;
  //   opacity: 0;
  // }
  .noContentBox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "SF UI Display Medium", sans-serif;
    font-size: 20px;
    color: lightgrey;
  }
  // &.noDrugs {
  //   grid-template-areas:
  //     "complaints complaints"
  //     "conclusion conclusion"
  //     "analysis analysis";
  // }
  .diagnosticBox {
    grid-area: diagnostic;
    margin-top: 10px;
    width: 100%;
    .diagnosticTitle {
      width: 100%;
      font-family: "SF UI Display Medium", sans-serif;
      font-size: 16px;
      text-align: center;
      margin-bottom: 10px;
    }
    .diagnosticList {
      padding: 10px;
      border-radius: 8px;
      background: #f4f9fd;
      .diagnosticItem {
        padding: 10px;
        font-family: "SF UI Display Regular", sans-serif;
        font-size: 15px;
        &:not(:last-child) {
          border-bottom: 1px solid lightgrey;
        }
      }
    }
  }
  .diseaseBox {
    grid-area: diagnostic;
    margin-top: 10px;
    width: 100%;
    .diseaseTitle {
      width: 100%;
      font-family: "SF UI Display Medium", sans-serif;
      font-size: 16px;
      text-align: center;
      margin-bottom: 10px;
    }
    .diseaseList {
      padding: 10px;
      border-radius: 8px;
      background: #f4f9fd;
      .diseaseItem {
        padding: 10px;
        font-family: "SF UI Display Regular", sans-serif;
        font-size: 15px;
        &:not(:last-child) {
          border-bottom: 1px solid lightgrey;
        }
      }
    }
  }
  // "analysis drugs";
  .complaintsInfo {
    // grid-area: complaints;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 10px;
    background: #f4f9fd;
    border-bottom: 1px solid lightgrey;
    .complaintsTitle {
      font-family: "SF UI Display Medium", sans-serif;
      font-size: 16px;
      margin-bottom: 10px;
    }
    .complaintsText {
      font-family: "SF UI Display Regular", sans-serif;
      font-size: 15px;
    }
  }
  .conclusionInfo {
    // grid-area: conclusion;
    padding: 10px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background: #f4f9fd;
    margin-bottom: 10px;
    .conclusionTitle {
      font-family: "SF UI Display Medium", sans-serif;
      font-size: 16px;
      margin-bottom: 10px;
    }
    .conclusionText {
      font-family: "SF UI Display Regular", sans-serif;
      font-size: 16px;
    }
  }
  .analysisItemsBox {
    // grid-area: analysis;
    margin-bottom: 15px;
    width: 100%;
    .analysisTitle {
      padding: 5px;
      font-family: "SF UI Display Medium", sans-serif;
      font-size: 16px;
      text-align: center;
    }
    .analysisItemsContainer {
      width: 100%;
      padding: 17px;
      // height: 100%;
      background: #f4f9fd;
      border-radius: 8px;
      .analysisItem {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        .selectContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 60px;
        }
        .itemInfo {
          font-family: "SF UI Display Regular", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          @media screen and (max-width: 1024px) {
            font-size: 13px;
          }
          line-height: 20px;
          color: #07141f;
          // width: 30%;
          text-align: left;
          &.column {
            color: #25445d;
          }
          &.center {
            text-align: center;
          }
        }
        .itemInfo:first-child {
          width: 50%;
        }
        .itemInfo:not(:first-child) {
          width: 25%;
          text-align: center;
        }
      }
    }
  }
  .drugsContainer {
    width: 100%;
    height: 100%;
    // grid-area: drugs;
    .btn {
      height: 40px;
      margin-top: 10px;
    }
    .drugListTitle {
      width: 100%;
      font-family: "SF UI Display Medium", sans-serif;
      font-size: 16px;
      text-align: center;
      background: white;
      padding: 10px;
    }
    .drugsListContainer {
      .analysisList {
        .analysisItem {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px;
          &.unavailable {
            opacity: 0.4;
          }
          .noItems {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: "SF UI Display Medium", sans-serif;
            font-size: 16px;
            opacity: 1;
            text-align: center;
          }
        }
      }
    }
  }
  .drugsListContainer {
    position: relative;
    // grid-area: drugs;
    padding: 5px;
    // padding-top: 30px;
    // height: 100%;
    // max-height: 100%;
    background: #f4f9fd;
    // .drugListTitle {
    //   width: 100%;
    //   position: absolute;
    //   top: 0;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   padding: 5px;
    //   font-family: "SF UI Display Medium", sans-serif;
    //   font-size: 16px;
    //   text-align: center;
    //   background: white;
    // }
    .analysisList {
      // position: relative;
      width: 100%;
      // height: 86%;
      margin-top: 50px;
      // padding-top: 30px;
      overflow-y: auto;
      .loadingProgress {
        width: 100%;
        height: 40px;
        padding: 5px;
        position: relative;
      }
      &::-webkit-scrollbar {
        width: 5px;
        height: 20px;
        border-radius: 2px;
        background: unset;
      }
      &::-webkit-scrollbar-thumb {
        width: 5px;
        height: 10%;
        height: 20px;
        border-radius: 2px;
        background: #3288cd;
      }
      .analysisItem {
        border-bottom: 1px solid #dae2e7;
        border-width: 0.1em;
        // border-op
        margin-bottom: 4px;
        width: 100%;
        min-height: 40px;
        max-height: 200px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 10px;
        &:hover,
        &:focus {
          cursor: pointer;
        }
        .checkBox {
          margin-right: 15px;
        }
        .analysTitle {
          font-family: "SF UI Display Regular", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          @media screen and (max-width: 1024px) {
            font-size: 13px;
          }
          min-width: 20px;
          line-height: 20px;
          // margin: 0 auto;
          // margin-right: 0;
          color: #07141f;
          &.toCenter {
            min-width: 40px;
            text-align: center;
          }
          // &.toRight
          &.real {
            min-width: 232px;
            max-width: 232px;
          }
        }
      }
    }
  }

  .analysisTableNames {
    background: #f4f9fd;
    position: absolute;
    top: 0px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    // padding: 5px 10px;
    display: flex;
    overflow-x: auto;
    .selectedCounter {
      z-index: 999;
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: white;
      // padding: 6px;
      width: 25px;
      height: 25px;
      font-family: "SF UI Display Regular", sans-serif;
      font-size: 13px;
      @media screen and (max-width: 1024px) {
        font-size: 10px;
      }
      border-radius: 50%;
      background: #3288cd;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .tableName {
      font-family: "SF UI Display Regular", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;

      @media screen and (max-width: 1024px) {
        font-size: 11px;
      }
      line-height: 20px;
      color: #25445d;
      margin-right: 0;
      &.toRight {
        // margin-left: auto;
        margin-left: 20px;
        margin-right: auto;
      }
      &.toCenter {
        width: 60%;
        // min-width: 232px;
        min-width: 262px;
        display: flex;
        justify-content: center;
        align-items: center;
        // max-width: 232px;
        text-align: center;
      }
    }
  }
}

///Material Select Styles
.MuiList-root {
  max-height: 120px;
}
