.sidebarContainer {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px;
  padding-top: 10px;
  padding-bottom: 15px;
  // background: #ffffff;
  background: #f6f7fb;
  border-radius: 8px;
  overflow: hidden;
  max-width: 300px;
  // -webkit-box-shadow: 0px 15px 10px 2px #d3d6df inset;
  // -moz-box-shadow: 0px 15px 10px 2px #d3d6df inset;
  // box-shadow: 0px 15px 10px 2px #d3d6df inset;
  @media screen and (max-width: 1025px) {
    display: none;
  }
  @media screen and (max-width: 1100px) {
    max-width: 200px;
  }
  .imageBox {
    width: 97%;
    max-height: 200px;
    height: 30%;
    padding-bottom: 10px;
    padding-top: 10px;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    opacity: 0.95;
    margin-left: 4px;
    border-bottom: 4px solid #3287cd36;
    border-top: 1px solid #3287cd17;
    background: #ffffff;
    &:hover {
      opacity: 1;
      -webkit-box-shadow: 0px 0px 3px 1px #3287cd36;
      -moz-box-shadow: 0px 0px 3px 1px #3287cd36;
      box-shadow: 0px 0px 3px 1px #3287cd36;
    }
    &.is-active {
      width: 30px;
    }
    .bannerImage {
      border-radius: 50%;
      // max-height: 180px;
      width: 80%;
      &.moreWidth {
        border-radius: 10px;
        width: 88%;
      }
      &.normal {
        width: 100%;
        border-radius: 0%;
      }
    }
  }
}
.splide__arrow--prev {
  &.your-class-prev {
    background: #f4f9fd;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
}

.splide__arrow--next {
  &.your-class-next {
    width: 25px;
    background: #f4f9fd;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
}

.splide__pagination__page {
  &.your-class-page {
    background: lightgray;
    // opacity: ;
  }
  &.your-class-page.is-active {
    background: #3288cd;
  }
}

.splide__pagination {
  display: flex;
  flex-direction: row;
  &.class-pagination {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
}

#splide01 > ul {
  width: 100px;
  bottom: -20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  left: 50%;
  transform: translateX(-50%);
  & li button {
    width: 10px;
    height: 10px;
  }
}
