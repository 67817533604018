.overlayBox {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.527);
  z-index: 99999999;
  .modalConfirmContainer {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 20px;
    animation: confirm 0.4s forwards ease-in-out;
    min-width: 400px;
    min-height: 200px;
  }
  .imageBox {
    position: relative;
    position: absolute;
    min-width: 500px;
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 90vw;
    max-height: 80vh;
    border-radius: 12px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .closeBox {
    width: 32px;
    height: 32px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -30px;
    top: -30px;
    border-radius: 50%;
    opacity: 0.7;
    // background: rgba(85, 76, 76, 0.438);
    background: rgba(255, 255, 255, 0.692);
    &:hover,
    &:focus {
      opacity: 1;
      cursor: pointer;
      // background: rgba(85, 76, 76, 0.733);
      background: white;
    }
  }
  .modalWindowBox {
    position: relative;
    min-height: 300px;
    min-width: 300px;
    position: absolute;
    width: auto;
    height: auto;
    background: white;
    border-radius: 12px;
    padding: 20px;
    left: 50%;
    top: 50%;
    overflow: hidden;
    transform: translate(-50%, -50%);
    max-width: 600px;
    width: 30%;
    height: auto;
    &.withPayment {
      width: 40%;
      height: 80%;
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
        width: 400px;
        height: 500px;
      }
    }
    @media screen and (max-width: 1024px) {
      max-height: 500px;
    }
    .closeIcon {
      position: absolute;
      width: 20px;
      height: 20px;
      right: 15px;
      top: 15px;
    }
    .contentBox {
      position: absolute;
      // height: 100%;
      // width: 100%;
      top: 45px;
      bottom: 0;
      left: 0;
      right: 0;
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
        z-index: 9999;
      }
      & .fondyContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
      .bookingSuccessContainer {
        // padding: 20px;
        .analysisStepButton {
          display: flex;
          justify-content: center;
          padding-bottom: 20px;
          height: 80px;
          align-items: center;
          .btn {
            width: 90%;
            margin: 0 auto;
          }
        }
      }
    }
  }
}

@keyframes confirm {
  from {
    opacity: 0;
    top: -50px;
  }
  to {
    opacity: 1;
    top: 20px;
  }
}
