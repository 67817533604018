.bookingSuccessContainer {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  position: relative;
  animation: stepForwardAnimation 0.3s ease-in-out;
  .bookingSuccessContent {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 250px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    & img {
      margin-bottom: 10px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    .bookingText {
      font-family: "SF UI Display Medium", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      @media screen and (max-width: 1024px) {
        font-size: 15px;
      }
      line-height: 30px;
      text-align: center;
      color: #07141f;
    }
  }
  .analysisStepButton {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    .btn {
      height: 50px;
    }
  }
}
