.searchContainer {
  z-index: 999;
  position: absolute;
  top: 70px;
  left: 0;
  width: 620px;
  max-height: 300px;
  // overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 20px;
  padding-right: 10px;
  padding-top: 20px;
  padding-left: 10px;
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  &.dn {
    display: none;
  }
  .searchItemContainer {
    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 8px;
      background: unset;
    }
    &::-webkit-scrollbar-thumb {
      width: 8px;
      // height: 10%;
      border-radius: 8px;
      background: #3287cdbe;
      opacity: 0.7;
    }
    &::-webkit-scrollbar-track {
      border-radius: 8px;
      box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.15);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #3288cd;
      cursor: pointer;
    }
    padding-right: 10px;
    overflow-y: auto;
  }
  .searchPlaceHolder {
    z-index: 9;
    // top: 20px;
    // left: 30px;
    background: white;
    font-family: "SF UI Display Medium", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    @media screen and (max-width: 1024px) {
      font-size: 13px;
    }
    padding-left: 20px;
    line-height: 20px;
    margin-bottom: 10px;
    color: #8aa0b1;
  }
  .searchItem {
    color: #07141f;
    // height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.2 ease-in;
    // border-radius: 8px;
    padding: 12px;
    padding-left: 20px;
    transition: all 0.05s ease-in;
    &.selected {
      // background: #e5e5e5;
      background: #f4f9fd;
      // border-right: 1px solid #3288cd;
      -webkit-box-shadow: 0px 0px 4px 1px #3287cd36;
      -moz-box-shadow: 0px 0px 4px 1px #3287cd36;
      box-shadow: 0px 0px 4px 1px #3287cd36;
    }
    &:hover,
    &:focus {
      // background: #e5e5e5;
      background: #f4f9fd;
      // border-right: 1px solid #3288cd;
      -webkit-box-shadow: 0px 0px 4px 1px #3287cd36;
      -moz-box-shadow: 0px 0px 4px 1px #3287cd36;
      box-shadow: 0px 0px 4px 1px #3287cd36;
      cursor: pointer;
    }
    // &:not(:last-child) {
    //   margin-bottom: 8px;
    // }
    .searchTitle {
      font-family: "SF UI Display Medium", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
