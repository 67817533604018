.fishkaContainer {
  position: relative;
  overflow: hidden;
  padding: 20px;
  border-radius: 15px;
  background: white;
  padding-top: 57px;
  max-width: 740px;
  -webkit-box-shadow: 0px 0px 4px 1px #3287cd69 inset;
  -moz-box-shadow: 0px 0px 4px 1px #3287cd69 inset;
  box-shadow: 0px 0px 4px 1px #3287cd69 inset;
  &.tall {
    padding-bottom: 0;
    .absoluteCenter {
      transform: translateY(200px);
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .formField.picker {
    height: 60px;
    max-height: 60px;
    padding-left: 3px;
    padding-right: 3px;
    .placeholder {
      left: 25px;
    }
  }
  .fieldSimpleSelect {
    .placeholder {
      left: 25px;
    }
  }
  #inputbirthDate {
    height: 60px;
    padding-left: 25px;
    font-size: 17px;
    &:focus {
      -webkit-box-shadow: 0px 0px 4px 1px #3287cd69;
      -moz-box-shadow: 0px 0px 4px 1px #3287cd69;
      box-shadow: 0px 0px 4px 1px #3287cd69;
    }
    // margin: 3px;
  }
  // animation-direction: ;
  // animation: addHeight 0.3s ease-in forwards;
  .dnone {
    display: none;
  }
  .fishkaTitle {
    position: absolute;
    top: 0;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    font-family: "SF UI Display Medium", sans-serif;
    font-size: 20px;
    @media screen and (max-width: 1024px) {
      font-size: 17px;
    }
    // background: #f2f2f2;
    background: #f4f9fd;
    padding: 15px;
    text-align: center;

    -webkit-box-shadow: 0px 0px 3px 0px #3287cd91 inset;
    -moz-box-shadow: 0px 0px 3px 0px #3287cd91 inset;
    box-shadow: 0px 0px 3px 0px #3287cd91 inset;
    // -webkit-box-shadow: 0px 0px 16px -4px rgba(34, 60, 80, 0.2) inset;
    // -moz-box-shadow: 0px 0px 16px -4px rgba(34, 60, 80, 0.2) inset;
    // box-shadow: 0px 0px 16px -4px rgba(34, 60, 80, 0.2) inset;
    border-bottom: 1px solid rgba(128, 128, 128, 0.308);
  }
  .fishkaDescription {
    font-family: "SF UI Display Regular", sans-serif;
    font-size: 17px;
    @media screen and (max-width: 1024px) {
      font-size: 14px;
    }
    margin: 0 auto;
    margin-top: 15px;
    width: 80%;
    text-align: center;
    .mainTitle {
      font-family: "Roboto Bold", sans-serif;
      font-size: 18px;
      @media screen and (max-width: 1024px) {
        font-size: 15px;
      }
      // display: flex;
      // justify-content: center;
      // align-items: center;
      .logoSmall {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        // margin-right: 5px;
        // margin-top: 5px;
      }
    }
  }
  .fishkaBannerBox {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    &.imgNone {
      width: 700px;
      height: 338px;
      & img {
        display: none;
      }
      & .preloader {
        display: flex;
        justify-content: space-between;
      }
    }
    .preloader {
      display: none;
      // margin-bottom: 20px;
      padding-bottom: 20px;
      height: 344px;
      .box {
        &:first-child {
          margin-right: 8px;
        }
        // padding: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 350px;
        height: 324px;
        border-radius: 15px;
        animation: pulseGrey 0.9s ease-in-out infinite;
        -webkit-box-shadow: 0px 0px 16px -4px rgba(34, 60, 80, 0.2) inset;
        -moz-box-shadow: 0px 0px 16px -4px rgba(34, 60, 80, 0.2) inset;
        box-shadow: 0px 0px 16px -4px rgba(34, 60, 80, 0.2) inset;
        // .preloadImage {
        //   width: 100px;
        //   height: 100px;
        //   border-radius: 50%;
        //   animation: pulseGrey 0.9s ease-in-out infinite;
        // }
      }
    }
    & img {
      width: 350px;
    }
    // justify-content: ;
  }
  .registerButton {
    padding: 10px 0;
    padding-bottom: 0;
    .btn {
      height: 50px;
      span {
        font-size: 18px;
        @media screen and (max-width: 1024px) {
          font-size: 15px;
        }
      }
    }
  }
}
.rdtPicker {
  z-index: 999999;
}

.fishkaFormStepContainer {
  width: 600px;
  .modeInfoContainer {
    & .columnContainer {
      height: 320px;
      max-height: 999px;
    }
  }
}

.fishkaSuccessContainer {
  width: 600px;
  height: 240px;
  margin: 15px 0;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  -webkit-box-shadow: 0px 0px 16px -4px rgba(34, 60, 80, 0.2) inset;
  -moz-box-shadow: 0px 0px 16px -4px rgba(34, 60, 80, 0.2) inset;
  box-shadow: 0px 0px 16px -4px rgba(34, 60, 80, 0.2) inset;
  .fishkaSuccessLogo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    animation: logoAnimation 0.5s ease-in-out forwards;
  }
  .successText {
    text-align: center;
    width: 80%;
    font-family: "Roboto Bold", sans-serif;
    font-size: 22px;
    @media screen and (max-width: 1024px) {
      font-size: 18px;
    }
    margin: 15px 0;
  }
}

.balanceBox {
  // width: 600px;
  width: 100%;
  height: 150px;
  // margin: 15px 0;
  margin-top: 10px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  -webkit-box-shadow: 0px 0px 10px 0px #3287cd91 inset;
  -moz-box-shadow: 0px 0px 10px 0px #3287cd91 inset;
  box-shadow: 0px 0px 10px 0px #3287cd91 inset;
  // -webkit-box-shadow: 0px 2px 10px 2px rgba(34, 60, 80, 0.2) inset;
  // -moz-box-shadow: 0px 2px 10px 2px rgba(34, 60, 80, 0.2) inset;
  // box-shadow: 0px 2px 10px 2px rgba(34, 60, 80, 0.2) inset;
  .balanceText {
    font-family: "Roboto Bold", sans-serif;
    font-size: 20px;
    @media screen and (max-width: 1024px) {
      font-size: 17px;
    }
  }
  .patientBonuses {
    display: flex;
    // width: 120px;
    width: auto;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .fishkaLogo {
      width: 30px;
      margin-right: 15px;
    }
    .balance {
      font-family: "Roboto Bold", sans-serif;
      font-size: 23px;
      @media screen and (max-width: 1024px) {
        font-size: 19px;
      }
      color: red;
    }
  }
}

@keyframes pulseGrey {
  0% {
    -webkit-box-shadow: 0px 0px 16px -4px rgba(34, 60, 80, 0.2) inset;
    -moz-box-shadow: 0px 0px 16px -4px rgba(34, 60, 80, 0.2) inset;
    box-shadow: 0px 0px 16px -4px rgba(34, 60, 80, 0.2) inset;
  }
  50% {
    -webkit-box-shadow: 0px 0px 16px -4px rgba(34, 60, 80, 0.521) inset;
    -moz-box-shadow: 0px 0px 16px -4px rgba(34, 60, 80, 0.521) inset;
    box-shadow: 0px 0px 16px -4px rgba(34, 60, 80, 0.521) inset;
  }
  100% {
    -webkit-box-shadow: 0px 0px 16px -4px rgba(34, 60, 80, 0.2) inset;
    -moz-box-shadow: 0px 0px 16px -4px rgba(34, 60, 80, 0.2) inset;
    box-shadow: 0px 0px 16px -4px rgba(34, 60, 80, 0.2) inset;
  }
}

@keyframes addHeight {
  from {
    height: 537px;
    width: 740px;
  }
  to {
    height: 647px;
    width: 640px;
  }
}

@keyframes noAuthirizedHeight {
  from {
    height: 537px;
    width: 740px;
  }
  to {
    height: 707px;
    width: 640px;
  }
}

@keyframes logoAnimation {
  from {
    transform: translateY(-200px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes decreaseHeight {
  from {
    height: 657px;
    width: 640px;
  }
  to {
    height: 537px;
    width: 740px;
  }
}

@keyframes successStep {
  from {
    height: 647px;
    width: 640px;
  }
  to {
    height: 400px;
    width: 640px;
  }
}
