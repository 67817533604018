.analysisPageContainer {
  width: 100%;
  height: 100%;
  max-width: 940px;
  max-height: 689px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 1024px) {
    max-height: 800px;
  }
  .analysisPageHeader {
    display: flex;
    align-items: center;
    @media screen and (max-width: 1024px) and (orientation: portrait) {
      margin: 10px 0px;
    }
    @media screen and (max-width: 1024px) and (orientation: landscape) {
      margin: 5px 0px;
    }
    .imageBox {
      // margin-right: 20px;
      width: 57px;
      height: 57px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 3px solid white;
      border-radius: 50%;
      background: #f4f9fd;
      box-shadow: 0px 0px 4px -1px #3288cd inset;
      margin-right: 10px;
    }

    .analysisImage {
      margin-right: 20px;
    }
    .analysisHeaderTitle {
      font-family: "SF UI Display Medium", sans-serif;
      font-style: normal;
      // font-weight: bold;
      // font-size: 30px;
      font-weight: 500;
      font-size: 23px;
      @media screen and (max-width: 1024px) {
        font-size: 25px;
      }
      line-height: 36px;
      color: #272d2d;
    }
  }
  .analysisContentContainer {
    width: 100%;
    display: flex;
    height: 100%;
    max-height: 589px;
    justify-content: space-between;
    @media screen and (max-width: 1024px) {
      max-height: 700px;
    }
    .animationContainer {
      // -webkit-box-shadow: 0px 0px 2px 0px rgba(34, 60, 80, 0.2) inset;
      // -moz-box-shadow: 0px 0px 2px 0px rgba(34, 60, 80, 0.2) inset;
      // box-shadow: 0px 0px 2px 0px rgba(34, 60, 80, 0.2) inset;
      // margin-right: 20px;
      width: 100%;
      max-width: 620px;
      height: 100%;
      max-height: 589px;
      // padding: 40px;
      padding: 20px;
      padding-bottom: 15px;
      // padding-top: 38px;
      background: #ffffff;
      border-radius: 8px;
      position: relative;
      // @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      //   width: 100%;
      //   max-width: 9999px;
      // }
      @media screen and (max-width: 1024px) {
        width: 100%;
        max-width: 9999px;
        max-height: 700px;
        border-radius: 0px;
        // border-top: 1px solid lightgrey;
        // border-bottom: 1px solid lightgrey;
      }
      @media screen and (max-width: 1024px) and (orientation: landscape) {
        border: none;
        box-shadow: none;
        border-radius: 0px;
      }
      .colorFieldBottom {
        position: absolute;
        // background: #f2f2f2;
        background: #f4f9fd;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        bottom: 0;
        height: 83px;
        width: 100%;
        left: 0px;
        -webkit-box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2) inset;
        -moz-box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2) inset;
        box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2) inset;
        @media screen and (max-width: 1024px) and (orientation: landscape) {
          // border: none;
          box-shadow: none;
          border-radius: 0px;
        }
      }
      .analysisContent {
        position: relative;
        overflow: hidden;
        width: 100%;
        max-width: 620px;
        height: 100%;
        max-height: 589px;
        .analysisStepCard .analysisStepContent .medContainer {
          max-height: 300px;
          .createCard {
            width: 100%;
            max-width: 100%;
          }
        }
        @media screen and (max-width: 1024px) {
          width: 100%;
          max-width: 9999px;
          max-height: 700px;
        }
      }
    }

    .analysisInfo {
      width: 30%;
      max-width: 300px;
      height: 100%;
      max-height: 589px;
      background: #ffffff;
      border-radius: 8px;
      -webkit-box-shadow: 0px 0px 2px 0px rgba(34, 60, 80, 0.2) inset;
      -moz-box-shadow: 0px 0px 2px 0px rgba(34, 60, 80, 0.2) inset;
      box-shadow: 0px 0px 2px 0px rgba(34, 60, 80, 0.2) inset;
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
        display: none;
      }
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }
}
@keyframes stepForwardAnimation {
  from {
    left: 100%;
    opacity: 0;
  }
  to {
    left: 0px;
    opacity: 1;
  }
}

////////STEP CARD
.analysisStepCard {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  animation: stepForwardAnimation 0.3s ease-in-out;
  .analysisStepButton {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    .btn {
      height: 40px;
    }
  }
  .analysisStepContent {
    .medContainer {
      max-height: 300px;
    }
  }
}

////STEP ANALYSIS
.analysisStepAnalysis {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  animation: stepForwardAnimation 0.3s ease-in-out;
  .analysisInfoContainer {
    position: relative;
    position: absolute;
    // bottom: -20px;
    height: 80px;
    left: -25px;
    right: -25px;
    padding-right: 25px;
    padding-left: 25px;

    // width: 110%;
    // padding-top: 3px;
    // position: absolute;
    // height: 20%;
    // bottom: -20px;
    // left: -20px;
    // right: -20px;
    // display: flex;
    background: #25445d;
    // padding-left: 20px;
    // padding-right: 20px;
    // flex-direction: column;
  }
  .priceContainer {
    bottom: 40px;
  }
  .analysisStepContent {
    width: 100%;
    .inputContainer {
      width: 100%;
      position: relative;
      margin-bottom: 10px;
      padding-left: 3px;
      padding-right: 3px;
      .searchAnalysis {
        position: relative;
        width: 100%;
        height: 40px;
        padding: 5px;
        padding-left: 40px;
        background: #f4f9fd;
        border-radius: 4px;
        font-family: "SF UI Display Regular", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        @media screen and (max-width: 1024px) {
          font-size: 14px;
        }
        line-height: 30px;
        border: none;
        color: #25445d;
        &:focus {
          -webkit-box-shadow: 0px 0px 4px 1px #3287cd69;
          -moz-box-shadow: 0px 0px 4px 1px #3287cd69;
          box-shadow: 0px 0px 4px 1px #3287cd69;
        }
      }
      .searchIcon {
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .analysisStepButton {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    .btn {
      height: 40px;
    }
  }
}

///STEP TOOLS
.analysisStepTools {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  // position: relative;
  .analysisStepTitle {
    display: flex;
    align-items: center;
    font-family: "SF UI Display Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    @media screen and (max-width: 1024px) {
      font-size: 16px;
    }
    line-height: 18px;
    color: #07141f;
  }
  .analysisStepCount {
    display: flex;
    align-items: center;
    font-family: "SF UI Display Regular", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    @media screen and (max-width: 1024px) {
      font-size: 15px;
    }
    line-height: 18px;
    text-align: right;
    color: #3288cd;
  }
}

/////CHECKBOX ANALYSIS
.analysisListContainer {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 70.5%;
  overflow: auto;
  .analysisTableNames {
    background: #ffffff;
    position: relative;
    // overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 10px;
    display: flex;
    overflow-x: auto;
    &.withDrugs {
      top: 32px;
    }
    .selectedCounter {
      z-index: 999;
      position: absolute;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
      color: white;
      // padding: 6px;
      width: 25px;
      height: 25px;
      font-family: "SF UI Display Regular", sans-serif;
      font-size: 13px;
      @media screen and (max-width: 1024px) {
        font-size: 10px;
      }
      border-radius: 50%;
      background: #3288cd;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .tableName {
      font-family: "SF UI Display Regular", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      @media screen and (max-width: 1024px) {
        font-size: 12px;
      }
      line-height: 20px;
      color: #25445d;
      margin-right: 0;
      &.toRight {
        // margin-left: auto;
        margin-left: 20px;
        margin-right: auto;
      }
      &.toCenter {
        width: 60%;
        // min-width: 232px;
        min-width: 262px;
        // max-width: 232px;
        text-align: center;
      }
    }
  }
  .analysisList {
    // position: relative;
    width: 100%;
    height: 86%;
    margin-top: 43px;
    overflow-y: auto;
    &.withDrugs {
      height: 83%;
      @media screen and (max-width: 1024px) and (orientation: landscape) {
        height: 77%;
      }
    }
    .loadingProgress {
      width: 100%;
      height: 40px;
      padding: 5px;
      position: relative;
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 20px;
      border-radius: 2px;
      background: unset;
    }
    &::-webkit-scrollbar-thumb {
      width: 5px;
      height: 10%;
      height: 20px;
      border-radius: 2px;
      background: #3288cd;
    }
    .analysisItem {
      // position: relative;
      border-bottom: 1px solid #dae2e7;
      border-width: 0.1em;
      // border-op
      margin-bottom: 4px;
      width: 100%;
      min-height: 40px;
      max-height: 200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 10px;
      &.unavailable {
        opacity: 0.3;
        pointer-events: none;
      }
      &:hover,
      &:focus {
        cursor: pointer;
      }
      .checkBox {
        margin-right: 15px;
      }
      .analysTitle {
        font-family: "SF UI Display Regular", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        @media screen and (max-width: 1024px) {
          font-size: 14px;
        }
        min-width: 20px;
        line-height: 20px;
        // margin: 0 auto;
        // margin-right: 0;
        color: #07141f;
        &.toCenter {
          min-width: 40px;
          text-align: center;
        }
        &.real {
          min-width: 232px;
          max-width: 232px;
        }
      }
    }
  }
}

////STEP TYPE
.analysisStepType {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  animation: stepForwardAnimation 0.3s ease-in-out;
  .analysisStepContent {
    margin: 0 auto;
    margin-top: 20px;
    max-width: 65%;
  }
  .analysisStepButton {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    .btn {
      height: 40px;
    }
  }
}

///STEP LABORATORIES

.analysisStepLaboratories {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  animation: stepForwardAnimation 0.3s ease-in-out;
  .analysisStepContent {
    max-height: 88%;
    // width: 98%;
    // overflow-y: auto;
    &::-webkit-scrollbar {
      width: 12px;
      border-radius: 8px;
      background: unset;
    }
    &::-webkit-scrollbar-thumb {
      width: 15px;
      // height: 10%;
      border-radius: 8px;
      background: #3287cdbe;
      opacity: 0.7;
    }
    &::-webkit-scrollbar-track {
      border-radius: 8px;
      box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.15);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #3288cd;
      cursor: pointer;
    }
    overflow-y: auto;
    max-height: 85%;
    .scrollContainer {
      height: 100%;
      overflow-y: auto;
      padding-bottom: 0;
      // max-height: 900px;
      &::-webkit-scrollbar {
        width: 5px;
        height: 20px;
        border-radius: 2px;
        background: unset;
      }
      &::-webkit-scrollbar-thumb {
        width: 5px;
        height: 10%;
        height: 20px;
        border-radius: 2px;
        background: #3288cd;
      }
    }
    .doctorToggleContainer {
      width: 98%;
    }
  }
  .analysisStepButton {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    .btn {
      height: 40px;
    }
  }
}

///STEP CONFIRMATION

.analysisStepConfirmation {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  animation: stepForwardAnimation 0.3s ease-in-out;
  .priceContainer {
    bottom: 40px;
  }
  .analysisStepContent {
    height: 100%;
    max-height: 80.5%;
    padding-right: 7px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 10px;
      border-radius: 8px;
      background: unset;
    }
    &::-webkit-scrollbar-thumb {
      width: 10px;
      // height: 10%;
      border-radius: 8px;
      background: #3287cdbe;
      opacity: 0.7;
    }
    &::-webkit-scrollbar-track {
      border-radius: 8px;
      box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.15);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #3288cd;
      cursor: pointer;
    }
    .analysisTitle {
      font-family: "SF UI Display Medium", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
      }
      line-height: 20px;
      color: #07141f;
      margin-bottom: 10px;
    }
    .analysisCardBox {
      margin-bottom: 15px;
      .analysisCard {
        width: 100%;
        padding: 15px;
        display: flex;
        background: #f4f9fd;
        border-radius: 8px;
        & > div {
          // height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title {
            font-family: "SF UI Display Medium", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            @media screen and (max-width: 1024px) {
              font-size: 14px;
            }
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #07141f;
          }
          .relation {
            font-family: "SF UI Display Medium", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            @media screen and (max-width: 1024px) {
              font-size: 12px;
            }
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #8aa0b1;
          }
        }
        & img {
          margin-right: 15px;
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }
    }
    .analysisItemsBox {
      margin-bottom: 15px;
      width: 100%;
      .analysisItemsContainer {
        width: 100%;
        padding: 17px;
        background: #f4f9fd;
        border-radius: 8px;
        .analysisItem {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px;
          .itemInfo {
            font-family: "SF UI Display Regular", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            @media screen and (max-width: 1024px) {
              font-size: 14px;
            }
            line-height: 20px;
            color: #07141f;
            // width: 30%;
            text-align: left;
            &.column {
              color: #25445d;
            }
            &.center {
              text-align: center;
            }
          }
          .itemInfo:first-child {
            width: 50%;
          }
          .itemInfo:not(:first-child) {
            width: 25%;
            text-align: center;
          }
        }
      }
    }
    .analysisLabBox {
      width: 100%;
      margin-bottom: 15px;
      .analysisLab {
        width: 100%;
        padding: 15px;
        display: flex;
        align-items: center;
        background: #f4f9fd;
        border-radius: 8px;
        & img {
          margin-right: 15px;
        }
        .labInfo {
          font-family: "SF UI Display Regular", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          @media screen and (max-width: 1024px) {
            font-size: 11px;
          }
          line-height: 20px;
          color: #07141f;
        }
      }
    }
  }
  .analysisStepButton {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    .btn {
      height: 40px;
    }
  }
}

////BOOKING SUCCESS

.bookingSuccessContainer {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  position: relative;
  animation: stepForwardAnimation 0.3s ease-in-out;
  .bookingSuccessContent {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 250px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    & img {
      margin-bottom: 10px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    .bookingText {
      font-family: "SF UI Display Medium", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
      color: #07141f;
    }
  }
  .analysisStepButton {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    .btn {
      height: 40px;
    }
  }
}

.MuiSelect-select:focus {
  background-color: #f4f9fd;
}
