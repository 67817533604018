.callChatContainer {
  width: 100%;
  height: 100%;
  max-width: 900px;
  background: #ffffff;
  border-radius: 8px;
  padding-bottom: 40px;
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loader {
    width: 99%;
    height: 99%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.isActive {
    // padding: 0;
    // padding-top: 20px;
  }
  .toolPanel {
    width: 100%;
    display: flex;
    position: absolute;
    // position: relative;
    bottom: 0;
    right: 0;
    left: 0;
    height: 80px;
    background: #3287cda4;
    .toolContainer {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      // position: absolute;
      // bottom: 10px;
      // left: 50%;
      // transform: translateX(-50%);
      width: 190px;
      max-width: 280px;
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      // background: #8b8c8f;
      padding: 5px;
      padding-left: 20px;
      padding-right: 20px;
      img {
        opacity: 0.7;
        &:hover,
        &:focus {
          opacity: 1;
          transform: scale(1.02);
        }
      }
      .showChatIcon {
        width: 40px;
        height: 40px;
      }
      // background: lightgrey;
      // -webkit-box-shadow: 0px 0px 8px 2px rgba(34, 60, 80, 0.2) inset;
      // -moz-box-shadow: 0px 0px 8px 2px rgba(34, 60, 80, 0.2) inset;
      // box-shadow: 0px 0px 8px 2px rgba(34, 60, 80, 0.2) inset;
    }
  }
  .callType {
    font-family: "SF UI Display Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    @media screen and (max-width: 1024px) {
      font-size: 15px;
    }
    line-height: 20px;
    color: #07141f;
  }
  .callInfoContainer {
    margin: auto 0;
    max-width: 40%;
    justify-self: center;
    align-self: center;
    height: 45%;
    min-height: 30%;
    max-height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .callImage {
      width: 158px;
      height: 158px;
      border-radius: 50%;
      box-shadow: 0 0 0 #3288cd;
      animation: pulse 2s infinite;
      &.isActive {
        animation: none;
      }
    }
    .callSpecialization {
      font-family: "SF UI Display Regular", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      @media screen and (max-width: 1024px) {
        font-size: 12px;
      }
      line-height: 20px;
      text-align: center;
      color: #07141f;
    }
    .callDoctorName {
      font-family: "SF UI Display Medium", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      @media screen and (max-width: 1024px) {
        font-size: 21px;
      }
      line-height: 30px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #07141f;
    }
    .incomingCall {
      font-family: "SF UI Display Regular", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      @media screen and (max-width: 1024px) {
        font-size: 12px;
      }
      line-height: 30px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #07141f;
    }
    .callDuration {
      font-family: "SF UI Display Regular", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      @media screen and (max-width: 1024px) {
        font-size: 12px;
      }
      line-height: 30px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #07141f;
    }
  }
  .toolsContainer {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 35%;
    display: flex;
    justify-content: space-between;
    .toolImage {
      transition: all 150ms ease-in-out;
      opacity: 0.8;
      &:hover,
      &:focus {
        cursor: pointer;
        opacity: 1;
        transform: scale(1.05);
      }
    }
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 #3288cd;
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px #3288cd;
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 #3288cd;
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 #3288cd;
    box-shadow: 0 0 0 0 #3288cd;
  }
  70% {
    -moz-box-shadow: 0 0 0 10px #3288cd;
    box-shadow: 0 0 0 10px #3288cd;
  }
  100% {
    -moz-box-shadow: 0 0 0 0 #3288cd;
    box-shadow: 0 0 0 0 #3288cd;
  }
}
