.priceContainer {
  position: absolute;
  bottom: 46px;
  width: 100%;
  // display: flex;
  // justify-content: space-between;
  // background: white;
  height: auto;
  @media screen and (max-width: 1024px) {
    bottom: 30px;
  }
  @media screen and (max-width: 1024px) and (orientation: portrait) {
    bottom: 40px;
  }
  .priceTitle {
    font-family: "SF UI Display Regular", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    @media screen and (max-width: 1024px) {
      font-size: 15px;
    }
    // line-height: px;
    color: #07141f;
    &.diff {
      font-family: "SF UI Display Medium", sans-serif;
      font-size: 16px;
      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }
      color: dimgrey;
    }
  }
  .priceBox {
    position: relative;
    // min-width: 40px;
    width: 100%;
    min-height: 40px;
    &.small {
      min-height: 22px;
      display: flex;
      align-items: flex-end;
    }
    .onlyPriceBox {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .fishkaPriceBox {
      @extend .onlyPriceBox;
    }
    .price {
      @extend .priceTitle;
      &.diff {
        color: dimgrey;
      }
    }
    .absoluteCenter {
      width: 30px;
      height: 30px;
      // right: 30px;
      top: 0;
      transform: translateX(230px);
      // right: 0px;
    }
  }
}
