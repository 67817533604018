/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "SF UI Display Regular";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Regular"), url("Roboto-Regular.woff") format("woff");
}

@font-face {
  font-family: "Roboto Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto Italic"), url("Roboto-Italic.woff") format("woff");
}

@font-face {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto Bold"), url("Roboto-Bold.woff") format("woff");
}

@font-face {
  font-family: "Roboto Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto Bold Italic"), url("Roboto-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Roboto Thin";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto Thin"), url("Roboto-Thin.woff") format("woff");
}

@font-face {
  font-family: "Roboto Thin Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto Thin Italic"), url("Roboto-ThinItalic.woff") format("woff");
}

@font-face {
  font-family: "Roboto Light";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto Light"), url("Roboto-Light.woff") format("woff");
}

@font-face {
  font-family: "SF UI Display Medium";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Medium"),
    url("SFUIDisplay-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "SF UI Display Regular";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Regular"),
    url("SFUIDisplay-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto Light Italic"),
    url("Roboto-LightItalic.woff") format("woff");
}

@font-face {
  font-family: "SF UI Display Medium";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Medium"), url("Roboto-Medium.woff") format("woff");
}

@font-face {
  font-family: "Roboto Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto Medium Italic"),
    url("Roboto-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "Roboto Black";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto Black"), url("Roboto-Black.woff") format("woff");
}

@font-face {
  font-family: "Roboto Black Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto Black Italic"),
    url("Roboto-BlackItalic.woff") format("woff");
}
