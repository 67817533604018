.dateSearchContainer {
  width: 350px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &.bigger {
    width: 500px;
    margin-right: 10px;
  }
  .searchTitleBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    .searchTitle {
      font-family: "SF UI Display Regular", sans-serif;
      font-size: 16px;
      margin-left: 10px;
      margin-right: 10px;
      color: #3288cd;
      &:hover {
        color: #3287cdc4;
      }
    }
  }
  .dateContainer {
    display: flex;
    align-items: center;
    padding-right: 3px;
    .line {
      margin-right: 10px;
      margin-left: 10px;
      width: 20px;
      height: 0px;
      border-bottom: 1px solid #3288cd;
    }
    .formField {
      margin: 0;
      font-size: 12px;
    }
    #inputstartDate,
    #inputendDate {
      font-size: 13px;
      box-shadow: 0px 0px 6px -2px #3288cd;
      padding: 8px;
      padding-left: 15px;
      max-width: 110px;
    }
  }
}
